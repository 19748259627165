import React, { useState } from 'react'
import './Sport.css'
import Soccer from './soprtsSection/Soccer';
import Basketball from './soprtsSection/BasketBall';
import FootBall from './soprtsSection/FootBall';
import IndoorSoccer from './soprtsSection/IndoorSoocer';
import VolleBall from './soprtsSection/VolleBall';
import { Link } from 'react-router-dom';
const Sport = () => {
    const [selectedSection, setSelectedSection] = useState("Soccer");
    const tabs = [
        {
            id:1,
            path:'soccer',
            title:'Soccer'

        },
        {
            id:2,
            path:'basketball',
            title:'Basketball'

        },
        {
            id:3,
            path:'football',
            title:'Football'

        },
        {
            id:4,
            path:'indoorsoccer',
            title:'IndoorSoccer'

        },
        {
            id:5,
            path:'volleyball',
            title:'Volleyball'

        },
    ]
  return (
    <>
    <div className="dashboard-body">
                <div className="position-relative">
                    <div className="earningContainer container p-1" >
                        <ul className="navbar-na  flex-wrap nav_align d-flex">
                            {
                                tabs && tabs.map((value,index)=>{
                                    return <li className="nav-item" key={index}>
                                        <Link
                                        to={`/settings/sport/${value?.path}`}
                                            className={`nav-link d-flex align-items-center px-2 ${selectedSection ===value?.title ? "active" : ""} btn  btn_type`}
                                            onClick={() => setSelectedSection(value?.title)}
                                            style={{ cursor: "pointer" }}
                                        >
                                            {value?.title}
                                        </Link>
                                    </li>
                                    
                                })
                            }
                            {/* <li className="nav-item">
                                <Link
                                to="/settings/sport/soccer"
                                    className={`nav-link d-flex align-items-center px-2 ${selectedSection === "Soccer" ? "active" : ""} btn  btn_type`}
                                    onClick={() => setSelectedSection("Soccer")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Soccer
                                </Link>
                            </li> */}
                            {/* <li className="nav-item">
                                <Link
                                to="/settings/sport/basketball"

                                    className={`nav-link ${selectedSection === "Basketball" ? "active" : ""} btn btn_type`}
                                    onClick={() => setSelectedSection("Basketball")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Basketball
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                to="/settings/sport/football"
                                    className={`nav-link ${selectedSection === "Football" ? "active" : ""} btn btn_type`}
                                    onClick={() => setSelectedSection("Football")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Football
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                to="/settings/sport/indoorsoccer"
                                    className={`nav-link ${selectedSection === "IndoorSoccer" ? "active" : ""} btn btn_type`}
                                    onClick={() => setSelectedSection("IndoorSoccer")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Indoor Soccer
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                to="/settings/sport/volleyball"
                                    className={`nav-link ${selectedSection === "Volleyball" ? "active" : ""} btn btn_type`}
                                    onClick={() => setSelectedSection("Volleyball")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Volleyball
                                </Link>
                            </li> */}
                        </ul>
                    </div>

                    <div id="overview" className="wrapper">
                        <div className="job-list-one style-two position-relative mb-20 container">
                            {selectedSection === "Soccer" && <Soccer />}
                            {selectedSection === "Basketball" && <Basketball />}
                            {selectedSection === "Football" && <FootBall />}
                            {selectedSection === "IndoorSoccer" && <IndoorSoccer />}
                            {selectedSection === "Volleyball" && <VolleBall />}

                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default Sport