import React from 'react'
import './PaymentPart2.css'
const paymentpart2 = () => {


  return (
    <div className="">
    <div className="row justify-content-between">
      <div className="col-lg-2">
        <div className="payment_cards-wrap">
          <h6>Payments</h6>
          <h1>992</h1>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="payment_cards-wrap">
          <h6>Payment Amount</h6>
          <h1>$127.33K</h1>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="payment_cards-wrap">
          <h6>Payment Average</h6>
          <h1>$128.35</h1>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="payment_cards-wrap">
          <h6>Refunds</h6>
          <h1>331</h1>
        </div>
      </div>
      <div className="col-lg-2">
        <div className="payment_cards_wrap">
          <h6>Refund Amount</h6>
          <h1>$8,859.2</h1>
        </div>
      </div>{" "}
      <div className="col-lg-2">
        <div className="payment_cards_wrap">
          <h6>Refund Average</h6>
          <h1>$26.77</h1>
        </div>
      </div>
    </div>
  </div>
  )
}

export default paymentpart2