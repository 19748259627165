import React from 'react';
import './Billing.css';
import strip from '../../../../../assets/logo/bi_stripe.png';
import paypal from '../../../../../assets/logo/ic_sharp-paypal.png';
import afterpay from '../../../../../assets/logo/simple-icons_afterpay.png';

const PaymentMethods = () => {
    return (
        <>
            <div className='container mt-5'>
                <div className='heading mb-4'>
                    <h2>Payment Integrations</h2>
                    <p>Connect any desired financial services to your product</p>
                </div>
                <div className='row p-1 types_cards justify-content-between'>
                    <div className="payment-method col-4 type-cards">
                        <h2>Stripe</h2>
                        <p>
                            <img src={strip} alt="Stripe" className='image_style' />
                            Lorem ipsum dolor sit amet consectetur. At eget facilisi venenatis pulvinar lectus augue.
                        </p>
                        <button className='Button_class'>Connect</button>
                    </div>
                    <div className="payment-method col-4 type-cards">
                        <h2>Paypal</h2>
                        <p>
                            <img src={paypal} alt="Paypal" className='image_style' />
                            Lorem ipsum dolor sit amet consectetur. At eget facilisi venenatis pulvinar lectus augue.
                        </p>
                        <button className='Button_class'>Connect</button>
                    </div>
                    <div className="payment-method col-4 type-cards">
                        <h2>Afterpay</h2>
                        <p>
                            <img src={afterpay} alt="Afterpay" className='image_style' />
                            Lorem ipsum dolor sit amet consectetur. At eget facilisi venenatis pulvinar lectus augue.
                        </p>
                        <button className='Button_class'>Connect</button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PaymentMethods;
