import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllUser, fetchsearchUser } from "../../Redux/Slice/UserSlice";
import ViewUserDetails from "./user-details/ViewUserDetails";
import DashboardChart from "../../components/dashboard/charts";
import "./Dashboard.css";
import NavigationBar from "../Header/Header";
import DeleteModal from "./user-details/UsersDelete";
import { Link, Navigate, useNavigate } from "react-router-dom";

const UserManagement = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [videoAccessToggles, setVideoAccessToggles] = useState([]);
  const [textAccessToggles, setTextAccessToggles] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false); // State for "Select All" checkbox
  const [checkedRows, setCheckedRows] = useState({}); // State to manage checked rows
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [showIndividualCheckboxes, setShowIndividualCheckboxes] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Users = useSelector((state) => state?.users?.users);
  const Userdata = Users || [];

  console.log(Users);
  useEffect(() => {
    dispatch(fetchAllUser());
  }, [dispatch]);

  useEffect(() => {
    if (Userdata && Userdata.length > 0) {
      setUsers(Userdata);
      // Initialize toggles state
      setVideoAccessToggles(new Array(Userdata.length).fill(false));
      setTextAccessToggles(new Array(Userdata.length).fill(false));
    } else {
      setUsers([]);
      setVideoAccessToggles([]);
      setTextAccessToggles([]);
    }
  }, [Userdata]);

  //   const handleSearchChange = (e) => {
  //     const searchValue = e.target.value;

  //     if (searchValue.trim() === '') {
  //         dispatch(fetchAllUser());
  //     } else {
  //         // Call the searchUser function and handle the response
  //         dispatch(fetchsearchUser(searchValue)).then((response) => {
  //             if (response.length > 0) {
  //                 dispatch(fetchsearchUser(searchValue));
  //             } else {
  //                 console.log("No results found for the given search criteria.");
  //             }
  //         }).catch((error) => {
  //             console.error("Error searching users:", error);
  //         });
  //     }
  //     setSearchTerm(searchValue);

  //     console.log("valuecheck for search", searchValue);
  // };

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.trim() === "") {
      dispatch(fetchAllUser());
    } else {
      dispatch(fetchsearchUser(searchValue));
    }
    setSearchTerm(searchValue);

    console.log("valuecheck for search", searchValue);
  };

  const handleViewDetails = (user, userName) => {
    setSelectedUser(user);
    // Navigate(`/home/${user?._id}`);
    navigate(`/user/${userName}`);
  };

  const handleCloseDetails = () => {
    setSelectedUser(null);
  };

  const handleVideoAccessChange = (user) => {
    const updatedToggles = videoAccessToggles.map((toggle, index) =>
      index === users.findIndex((u) => u.id === user.id) ? !toggle : toggle
    );
    setVideoAccessToggles(updatedToggles);
  };

  const handleTextAccessChange = (user) => {
    const updatedToggles = textAccessToggles.map((toggle, index) =>
      index === users.findIndex((u) => u.id === user.id) ? !toggle : toggle
    );
    setTextAccessToggles(updatedToggles);
  };

  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;
    setSelectAllChecked(checked);

    // Update the checked state for all rows
    const updatedCheckedRows = {};
    users.forEach((user) => {
      updatedCheckedRows[user.id] = checked;
    });
    setCheckedRows(updatedCheckedRows);

    // Update visibility of individual row checkboxes
    setShowIndividualCheckboxes(checked);
  };

  const handleRowCheckboxChange = (id) => {
    const isChecked = checkedRows[id];
    setCheckedRows((prev) => ({
      ...prev,
      [id]: !isChecked,
    }));

    // Check if all individual checkboxes are unchecked to uncheck "Select All"
    const allUnchecked = users.every((user) => !checkedRows[user.id]);
    if (allUnchecked) {
      setSelectAllChecked(false);
    }
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const handleDeleteIconClick = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    console.log("close modal");
    setShowDeleteModal(false);
    setUserToDelete(null);
  };

  return (
    <>
      <NavigationBar />
      {!selectedUser && <DashboardChart />}
      {selectedUser ? (
        <ViewUserDetails user={selectedUser} onClose={handleCloseDetails} />
      ) : (
        <>
          <div className=" m-5 bg-light">
            <h4 className="pb-3">User Management</h4>
            <div className="usermanagement_chart_wrap">
              <div className="row">
                <div className="col-md-12">
                  <div className="usermanagement_chart_header ">
                    <div className="position-relative h-23 d-flex justify-content-center align-items-center col-md-4">
                      <input
                        type="text"
                        className="form-control text-strat search-input rounded-pill"
                        placeholder="Search here"
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />
                      <span className="search-icon text-align-center">
                        <i className="bi bi-search"></i>
                      </span>
                    </div>

                    <div className="d-flex justify-content-center align-items-center gap-2 col-md-6 ">
                      <div className="h-23 px-2  py-2 d-flex justify-content-between align-items-center col-md-4 border border-success rounded-pill">
                        <p className="mb-0 text-left flex-grow-1 fs-sm-6">
                          Subscription plan
                        </p>
                        <i className="fa-regular fa-chevron-right" style={{color:"#111212"}}></i>
                      </div>
                      <div className="h-23 px-2  py-2 d-flex justify-content-between align-items-center col-md-4 border border-success rounded-pill">
                        <p className="mb-0 text-left flex-grow-1">
                          Ranking plan
                        </p>
                        <i className="fa-regular fa-chevron-right" style={{color:"#111212"}}></i>

                      </div>

                      <div className="h-23 px-2  py-2 d-flex justify-content-between align-items-center col-md-4 border border-success rounded-pill">
                        <p className="mb-0 text-left flex-grow-1">User Group</p>
                        <i className="fa-regular fa-chevron-right" style={{color:"#111212"}}></i>

                      </div>
                    </div>
                    <div>
                      <div className="justify-content-end d-flex align-items-center gap_20px">
                        <label>
                          Select All{" "}
                          <input
                            type="checkbox"
                            checked={selectAllChecked}
                            onChange={handleSelectAllChange}
                          />
                        </label>
                        <a>
                          Delete
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="table_body_wrap">
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th>
                            {/* {showIndividualCheckboxes && (
                            <input
                              type="checkbox"
                              checked={selectAllChecked}
                              onChange={handleSelectAllChange}
                            />
                          )} */}
                          </th>
                          <th>Sr. No.</th>
                          <th>User Name</th>
                          <th>User Id</th>
                          <th>Join Date</th>
                          <th>Email ID</th>
                          <th>Type of User</th>
                          <th>Subscription</th>
                          <th>Video Access</th>
                          <th>Tool Access</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {users &&
                          users.map((user, index) => (
                            <tr key={user._id}>
                              <td>
                                {showIndividualCheckboxes && (
                                  <input
                                    type="checkbox"
                                    checked={checkedRows[user.id] || false}
                                    onChange={() =>
                                      handleRowCheckboxChange(user)
                                    }
                                  />
                                )}
                              </td>
                              <td>{index + 1}.</td>
                              <td>{user.Name}</td>
                              <td>#{user.uniqueId}</td>
                              <td>{formatDate(user.created_at)}</td>
                              <td>{user.email}</td>
                              <td>{user.role}</td>
                              <td>{user?.subscriptionId?.subscriptionName}</td>
                              <td>
                                <div className="Switch_btn_wrap">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={videoAccessToggles[user.id]}
                                      onChange={() =>
                                        handleVideoAccessChange(user._id)
                                      }
                                    />
                                    <span className="slider"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <div className="Switch_btn_wrap">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={textAccessToggles[user.id]}
                                      onChange={() =>
                                        handleTextAccessChange(user._id)
                                      }
                                    />
                                    <span className="slider"></span>
                                  </label>
                                </div>
                              </td>
                              <td>
                                <a
                                  className="btn row_end_btns"
                                  onClick={() =>
                                    handleViewDetails(user, user.Name)
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-eye-slash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                    <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                    <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                                  </svg>
                                </a>

                                <a
                                  className="btn row_end_btns"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#exampleModal"
                                  onClick={() => handleDeleteIconClick(user)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="red"
                                    className="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                                    <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                                  </svg>
                                </a>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center gap-17px align-items-center">
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous">
                          <span aria-hidden="true">Previous</span>
                        </a>
                      </li>
                      <li className="page-item count_btn_wrap active">
                        <a className="page-link clr" href="#">
                          1
                        </a>
                      </li>
                      <li className="page-item count_btn_wrap">
                        <a className="page-link" href="#">
                          2
                        </a>
                      </li>
                      <li className="page-item count_btn_wrap">
                        <a className="page-link" href="#">
                          3
                        </a>
                      </li>
                      <li className="page-item">
                        <a className="page-link" href="#" aria-label="Next">
                          <span aria-hidden="true">Next</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <DeleteModal
        user={userToDelete}
        onClose={() => handleCloseDeleteModal()}
      />
    </>
  );
};

export default UserManagement;
