import React, { useState } from "react";
import { Card, CardContent, Typography } from "@mui/material";
import Dollor from "../../../../../assets/logo/dollor icon.png";
import logo2 from "../../../../../assets/logo/cllendedollor.png";
import logo3 from "../../../../../assets/logo/avrageorg.png";
import logo4 from "../../../../../assets/logo/greengroup.png";
import logo5 from "../../../../../assets/logo/bluepersent.png";
import ThreeDots from "../../../../../assets/three-dots.svg";
import CalenderIcon from "../../../../../assets/calender-icon.svg";
import StatusChart from "../../../../../assets/status-chart.svg";
import CountryMap from "../../../../../assets/country-map.svg";
import "./Overview.css";
import SubscriptionChart2 from "./SubscriptionChart2";
import ProgressBarChart1 from "./ProgressBarChart1";
import WorldChart from "./WorldChart";

const Overview = () => {
  const [progressValues, setProgressValues] = useState({
    monthly: 0.75,
    daily: 0.5,
    annually: 0.33,
  });

  const handleProgressChange = (event, type) => {
    const newProgressValues = { ...progressValues };
    newProgressValues[type] = parseFloat(event.target.value);
    setProgressValues(newProgressValues);
  };

  return (
    <>
      {/* Subscriptions */}
      <div className="m-5">
      <div className=" main_wrap_for_overviews">
        <div className="row justify-content-start gap-2px">
          <Card className=" card cardsize col-3">
            <CardContent className="card-content ">
              <div className="d-inline-flex gap-24px ">
                <img src={Dollor} alt="dollor" width="40px" height="45px" />
                <div>
                  <Typography className="payment-title" component="div">
                    Subscription
                  </Typography>
                  <Typography className="payment-amount " component="div">
                    <span className="h4"> 1,254</span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>

          <Card className="card  cardsize">
            <CardContent className="card-content">
              <div className="d-inline-flex gap-24px">
                <img src={logo2} alt="dollor" width="40px" height="45px" />
                <div>
                  <Typography
                    className="payment-title titledir"
                    component="div"
                  >
                    MRR
                  </Typography>
                  <Typography className="payment-amount" component="div">
                    <span className="h4">$57,734.28K </span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="card  cardsize">
            <CardContent className="card-content">
              <div className="d-inline-flex gap-24px">
                <img src={logo3} alt="dollor" width="40px" height="45px" />
                <div>
                  <Typography
                    className="payment-title titledir"
                    component="div"
                  >
                    Ave. MRR
                  </Typography>
                  <Typography className="payment-amount" component="div">
                    <span className="h4"> $41.09 </span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="card cardsize">
            <CardContent className="card-content">
              <div className="d-inline-flex gap-24px">
                <img src={logo4} alt="dollor" width="40px" height="45px" />
                <div>
                  <Typography
                    className="payment-title titledir"
                    component="div"
                  >
                    Discount
                  </Typography>
                  <Typography className="payment-amount" component="div">
                    <span className="h4"> $3,824.92</span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="card cardsize">
            <CardContent className="card-content">
              <div className="d-inline-flex gap-24px">
                <img
                  className=""
                  src={logo5}
                  alt="dollor"
                  width="40px"
                  height="45px"
                />
                <div>
                  <Typography
                    className="payment-title titledir"
                    component="div"
                  >
                    Ave. Discount
                  </Typography>
                  <Typography className="payment-amount" component="div">
                    <span className="h4"> $2.75</span>
                  </Typography>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>

      {/* Top Subscription Prices */}

      <div className=" main_wrap_for_overviews main_wrap_for_Subscription">
        <div className="row justify-content-start mt-50px d-l-g-block">
          <Card className="card col-md-6 col-lg-4  cardsize-4 width-50 mb-2">
            <div className="subscription_card_body_wrap">
              <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
                <h6>Top Subscription Prices</h6>
                <div className="d-flex align-items-center gap-30px">
                  <img src={CalenderIcon} alt="" />
                  <img src={ThreeDots} alt="" />
                </div>
              </div>

              <div className="progress-bar-item">
               <ProgressBarChart1/>
            </div>
            </div>
          </Card>

          <Card className="card col-md-6 col-lg-4 cardsize-4 width-50 mb-2">
            <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
              <h6>Subscriptions by Status</h6>
              <div className="d-flex align-items-center gap-30px">
                <img src={CalenderIcon} alt="" />
                <img src={ThreeDots} alt="" />
              </div>
            </div>
           <SubscriptionChart2/>
            <div className="col-6"></div>
          </Card>
          <Card className="card col-md-6 col-lg-12 cardsize-4 width-50 mb-2">
            <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
              <h6>Customers by country</h6>
              <div className="d-flex align-items-center gap-30px">
                {/* <img src={CalenderIcon} alt="" /> */}
                <img src={ThreeDots} alt="" />
              </div>
            </div>


            {/* country chart */}
            <div className="country_map">
              <WorldChart/>
            </div>
          </Card>
        </div>
      </div>
      </div>


      {/* bottom cards */}
      <div className="m-5">
        <div className="row">
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Payments</h6>
              <h1>992</h1>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Payment Amount</h6>
              <h1>$127.33K</h1>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Payment Average</h6>
              <h1>$128.35</h1>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Refunds</h6>
              <h1>331</h1>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Refund Amount</h6>
              <h1>$8,859.2</h1>
            </div>
          </div>{" "}
          <div className="col-lg-2">
            <div className="payment_cards_wrap">
              <h6>Refund Average</h6>
              <h1>$26.77</h1>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Overview;
