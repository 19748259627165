import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllClub,CreateClub } from "../Api/ClubApi"




const initialState = {
    clubs: [],
    page: 1,
    loading: false,
    error: null,
};

export const createClub = createAsyncThunk  (
    'clubs/createClub',
    async (data) => {
        const { values, avatarFil } = data
        try {
            const response = await CreateClub(values);
            const imageid = response.data.data._id
            // if (response.data.isSuccess) {
            //     createProfile({ imageid, avatarFil })
            // }
            return response.data;
        } catch (error) {
            console.error("Error creating user:", error);
            throw error;
        }
    }
);



export const fetchAllClub = createAsyncThunk(
    'clubs/fetchAllClub',
    async (data) => {
        try {
          const response = await getAllClub(data);
          console.log(response,"121212121jkhkjkhkjhk")
            return response.data;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    }
);









const clubSlice = createSlice({
    name: 'ClubSlice',
    initialState,
    reducers: {
      setPage:(state,action)=>{
        state.page=action.payload
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(createClub.fulfilled, (state, action) => {
            state.loading = false;
          })
          .addCase(createClub.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(createClub.rejected, (state, action) => {
            state.loading = false;
            state.error = "error";
          })
            // get all users----------

            .addCase(fetchAllClub.pending, (state) => {
              state.status = 'loading';
            })
            .addCase(fetchAllClub.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.users = action.payload;
            })
            .addCase(fetchAllClub.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.error.message;
            });
         

            // delete----------

          // .addCase(fetchdeleteUser.fulfilled, (state, action) => {
          //   state.loading = false;
          // })

          // search

          // .addCase(fetchsearchUser.fulfilled,(state,action)=>{
          //   state.users = action.payload;
          //   state.loading = false;
          // })
          
          
          
      },
    });
    export const { setPage } = clubSlice.actions
    export default clubSlice.reducer;
    