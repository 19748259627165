import React from 'react';
import Chart from 'react-apexcharts';

const ContentChart = ({ match, reels }) => {
  const options = {
    chart: {
      width: 380,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: 270,
        endAngle: -90,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    labels: ['Match', 'Reels'],
    legend: {
      show: false,
    },
    colors: ['rgb(236,161,255)', 'rgb(102,173,255)'], // Match and Reels colors
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  };

  const series = [match, reels];

  return (
    <div className="home_card1 ">
      <div className="total_content">
        <h6 className="main_head">Total Content</h6>
        <div className="total_content_chart text-center">
          <Chart options={options} series={series} type="donut" height={750} />
        </div>
        <div className="match_reels_wrap">
          <div className="d-flex justify-content-between align-items-center mb-20px chart_reading_info">
            <h6>
              <span className="home_chart_color_btn1" style={{backgroundColor: 'rgb(236,161,255)'}}></span> Match
            </h6>
            <p>{match}</p>
          </div>
          <div className="d-flex justify-content-between align-items-center mb-20px chart_reading_info">
            <h6>
              <span className="home_chart_color_btn" style={{backgroundColor: 'rgb(102,173,255)'}}></span> Reels
            </h6>
            <p>{reels}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentChart;
