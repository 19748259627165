import React, { useState } from 'react';
// import "./ProgressBar.css";

const BarChart1 = () => {
    const [progressValues, setProgressValues] = useState({
        monthly: 2171.14,
        daily: 1702.14,
        annually: 1512.36
    });

    const maxProgress = Math.max(progressValues.monthly, progressValues.daily, progressValues.annually);

    const Progress = ({ value, color }) => {
        const done = (value / maxProgress) * 50;

        // Construct backgroundColor from color object
        const backgroundColor = `rgb(${color.red}, ${color.green}, ${color.blue})`;

        const style = {
            opacity: 1,
            width: `${done}%`,
            backgroundColor: backgroundColor,
            borderRadius: '50px'
        };

        return (
            <div className="progress">
                <div className="progress-done" style={style}>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className="progress-bar-item">
                <div className="progress-bar-label" >
                    <label htmlFor="monthly">Monthly:</label>
                    <span className="progress-bar-value" >
                        ${progressValues.monthly}
                    </span>
                </div>
                <Progress value={progressValues.monthly} color={{ red: 40, green: 199, blue: 111 }} />
            </div>

            <div className="progress-bar-item">
                <div className="progress-bar-label">
                    <label htmlFor="daily">Daily:</label>
                    <span className="progress-bar-value">
                        ${progressValues.daily}
                    </span>
                </div>
                <Progress value={progressValues.daily} color={{ red: 47, green: 161, blue: 28 }} />
            </div>

            <div className="progress-bar-item">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Annually:</label>
                    <span className="progress-bar-value">
                        ${progressValues.annually}
                    </span>
                </div>
                <Progress value={progressValues.annually} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
        </div>
    );
};

export default BarChart1;
