import React, { useEffect, useState } from 'react';
import { fetchAllUser } from '../../../../../Redux/Slice/UserSlice';
import { useDispatch, useSelector } from 'react-redux';
import './CustomerTable.css'; // Make sure to include the CSS file

const CustomerTable = () => {
  const [users, setUsers] = useState('');

  const dispatch = useDispatch();
  const Users = useSelector((state) => state?.users?.users);
  const Userdata = Users || [];
 
  useEffect(() => {
    dispatch(fetchAllUser());
  }, [dispatch]);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="usermanagement_chart_wrap">
      <div className="row-lg">
        <div className="col-12">
          <div className="customer_chart_header">
            <div className="row align-items-center">
              <h4 className="col-12 col-md-3">New Customers</h4>
              {/* Uncomment and adjust search input if needed */}
              {/* <div className="col-12 col-md-6 position-relative">
                <input
                  type="text"
                  className="form-control text-start search-input rounded-pill"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <i className="bi bi-search"></i>
                </span>
              </div> */}
              {/* Uncomment and adjust actions if needed */}
              {/* <div className="col-12 col-md-3">
                <div className="justify-content-end d-flex align-items-center gap-20px">
                  <a>
                    Select All {"  "}
                    <input type="checkbox" />
                  </a>
                  <a>
                    Delete
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="red"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                    </svg>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <div className="table_body_wrap">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>User Name</th>
                  <th>User Id</th>
                  <th>Join Date</th>
                  <th>Email ID</th>
                  <th>Type of User</th>
                  <th>Subscription</th>
                  <th>Country</th>
                </tr>
              </thead>
              <tbody>
                {Users && Users.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user?.Name}</td>
                    <td>{user?.uniqueId}</td>
                    <td>{formatDate(user?.created_at)}</td>
                    <td>{user?.email}</td>
                    <td>{user?.role}</td>
                    <td>{user?.subscriptionId?.subscriptionName}</td>
                    <td>{user["Country"]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination */}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-17px align-items-center">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">Previous</span>
                </a>
              </li>
              <li className="page-item count_btn_wrap active">
                <a className="page-link clr" href="#">
                  1
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default CustomerTable;
