import axios from "axios"
import BaseUrl from "../../utils/Baseurl"


export const CreateUsers=async(data)=>{
    try {
        const resoponse = await axios.post(`https://mosis-backend.cradle.services/User/create`,data)
        return resoponse
    } catch (error) {
      console.error(error)
      throw error;    
    }
} 


export const getAlluser=async(data)=>{
  
    //  const option={
    //   headers: {
         
    //       'x-access-token': token
    //     }
    //  } 
      try {
          const resoponse = await axios.get(`${BaseUrl}User/getall?page_no=1&page_size=111`,data)
          return resoponse.data
      } catch (error) {
        console.error(error) 
        throw error;   
      }
  }

  export const getbyiduser = async (data) => {
    
    const id=data;

  
    try {
      const response = await axios.get(`${BaseUrl}User/getById/${id}`);
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };
  
  export const deleteUser=async(id)=>{

    
    console.log(id,"myid for delete")
      // const option={
      //  headers: {
          
      //      'x-access-token': token
      //    }
      // }
       try {
           const resoponse = await axios.delete(`${BaseUrl}User/remove/${id}`)
           return resoponse
       } catch (error) {
         console.error(error) 
         throw error;   
       }
   }

   export const searchUser = async (searchValue) => {
 
    console.log(searchValue,"my name search api")
  
    try {
      const response = await fetch(
        `${BaseUrl}User/search?Name=${searchValue}`,
        // options
      );
  
      if (!response.ok) {
        throw new Error(`Failed to fetch data. Status: ${response.status}`);
      }
  
      const responseData = await response.json();
      
      return responseData; // Assuming the response is already JSON, no need to stringify.
    } catch (error) { 
      console.error(error);
      throw error;
    }
  };


  //  export const sortUser =async(sort)=>{
  //   const options = {
  //     method: 'GET',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }
  
  //   try {
  //     const response = await fetch(
  //       `${BaseUrl}User/sortByName=${sort}`,
  //       options
  //     );
  //     const responseData = await response.json();
      
  //     return responseData; 
  //   } catch (error) {
  //     console.error(error);
  //     throw error;
  //   }
  // }