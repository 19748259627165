import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAlluser, CreateUsers, getbyiduser, deleteUser, searchUser } from "../Api/UserApi"




const initialState = {
  users: [],
  page: 1,
  loading: false,
  error: null,
};

export const createUser = createAsyncThunk(
  'users/createUser',
  async (data) => {
    const { values, avatarFil } = data
    try {
      const response = await CreateUsers(values);
      // const imageid = response.data.data._id
      // if (response.data.isSuccess) {
      //     createProfile({ imageid, avatarFil })
      // }
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }
);



export const fetchAllUser = createAsyncThunk(
  'users/fetchAllUser',
  async (data) => {
    try {
      const response = await getAlluser(data);
      console.log(response, "121212121jkhkjkhkjhk")
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const getbyidusers = createAsyncThunk(
  'users/getbyidusers',
  async (data) => {
   
    try {
      const response = await getbyiduser(data);
      return response.data
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);
export const fetchdeleteUser = createAsyncThunk(
  'users/fetchdeleteUser',
  async (id) => {
    try {
      const response = await deleteUser(id);
      console.log(response, "Deleteusers")

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const fetchsearchUser = createAsyncThunk(
  'users/fetchsearchUser',
  async (searchValue) => {
    console.log(searchValue,"check search")
    try {
      const response= await searchUser(searchValue);
      return response.data ;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }
);








const userSlice = createSlice({
  name: 'UserSlice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(createUser.pending, (state, action) => { 
        state.loading = true;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = "error";
      })
      // get all users----------

      .addCase(fetchAllUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchAllUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

            // usergetbyid-----
     .addCase(getbyidusers.fulfilled, (state, action) => {
        state.users = action.payload;
        state.loading = false;
      })

    // delete----------

    .addCase(fetchdeleteUser.fulfilled, (state, action) => {
      state.loading = false;
    })

  // search

  .addCase(fetchsearchUser.pending, (state) => {
    state.loading = true;
  })
  .addCase(fetchsearchUser.fulfilled, (state, action) => {
    state.users = action.payload;
    state.loading = false;
  })
  .addCase(fetchsearchUser.rejected, (state, action) => {
    state.error = action.error.message;
    state.loading = false;
  });



},
    });
export const { setPage } = userSlice.actions
export default userSlice.reducer;
