import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import {  CreateTeamMember } from '../../../Redux/Api/TeamApi'; 
import { toast } from 'react-toastify';
import { createTeamMember } from '../../../Redux/Slice/TeamSlice';


const AddMemberModal = ({ show, handleClose }) => {
  const initialValues = {
    Name: '',
    email: '',
    Work: '',
    role: ''
  };

  const validationSchema = Yup.object().shape({
    Name: Yup.string().required('Full Name is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    Work: Yup.string().required('Work Id is required'),
    role: Yup.string().required('Role is required')
  });

  const dispatch = useDispatch();

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      console.log('Form values:', values);
     
      const res = await dispatch(createTeamMember(values));
      console.log('Response:', res);
      toast.success('Team member added successfully!');
     
    } catch (error) {
      console.error('Error:', error);
      if (error.response && error.response.status === 409) {
        toast.error('Team member already exists.');
      } else {
        toast.error('Failed to add team member. Please try again.');
      }
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };
  
  

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className="titlename">Add Team Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formUserName">
                <Form.Label>
                  Full Name <sup style={{ color: 'red' }}>*</sup>
                </Form.Label>
                <Field type="text" name="Name" className="form-control" />
                <ErrorMessage name="Name" component="div" className="error-message" />
              </Form.Group>
              <Form.Group controlId="formEmail">
                <Form.Label>
                  Email Id <sup style={{ color: 'red' }}>*</sup>
                </Form.Label>
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage name="email" component="div" className="error-message" />
              </Form.Group>
              <Form.Group controlId="formWorkId">
                <Form.Label>
                  Work Id <sup style={{ color: 'red' }}>*</sup>
                </Form.Label>
                <Field type="text" name="Work" className="form-control" />
                <ErrorMessage name="Work" component="div" className="error-message" /> 
              </Form.Group>
              <Form.Group controlId="formRole">
                <Form.Label>
                  Role <sup style={{ color: 'red' }}>*</sup>
                </Form.Label>
                <Field type="text" name="role" className="form-control" />
                <ErrorMessage name="role" component="div" className="error-message" />
              </Form.Group>
              <br />
              <div className='for_button'>
              <Button className=" alignbtn btn" type="submit" disabled={isSubmitting}>
                {/* {isSubmitting ? 'Adding...' : 'ADD'} */}ADD
              </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default AddMemberModal;
