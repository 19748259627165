import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllAgent,CreateAgent } from "../Api/AgentApi"




const initialState = {
    agents: [],
    page: 1,
    loading: false,
    error: null,
};

export const createAgent = createAsyncThunk  (
    'agents/createAgent',
    async (data) => {
        const { values, avatarFil } = data
        try {
            const response = await CreateAgent(values);
            const imageid = response.data.data._id
            // if (response.data.isSuccess) {
            //     createProfile({ imageid, avatarFil })
            // }
            return response.data;
        } catch (error) {
            console.error("Error creating user:", error);
            throw error;
        }
    }
);



export const fetchAllAgent = createAsyncThunk(
    'agents/fetchAllAgent',
    async (data) => {
        try {
          const response = await getAllAgent(data);
          console.log(response,"121212121jkhkjkhkjhk")
            return response.data;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    }
);









const agentSlice = createSlice({
    name: 'AgentSlice',
    initialState,
    reducers: {
      setPage:(state,action)=>{
        state.page=action.payload
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(createAgent.fulfilled, (state, action) => {
            state.loading = false;
          })
          .addCase(createAgent.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(createAgent.rejected, (state, action) => {
            state.loading = false;
            state.error = "error";
          })
            // get all users----------

            .addCase(fetchAllAgent.pending, (state) => {
              state.status = 'loading';
            })
            .addCase(fetchAllAgent.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.users = action.payload;
            })
            .addCase(fetchAllAgent.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.error.message;
            });
         

            // delete----------

          // .addCase(fetchdeleteUser.fulfilled, (state, action) => {
          //   state.loading = false;
          // })

          // search

          // .addCase(fetchsearchUser.fulfilled,(state,action)=>{
          //   state.users = action.payload;
          //   state.loading = false;
          // })
          
          
          
      },
    });
    export const { setPage } = agentSlice.actions
    export default agentSlice.reducer;
    