import axios from "axios"
import BaseUrl from "../../utils/Baseurl"


export const CreateClub=async(data)=>{
    try {
        const resoponse = await axios.post(`https://mosis-backend.cradle.services/Club/create`,data)
        return resoponse
    } catch (error) {
      console.error(error)
      throw error;    
    }
}


export const getAllClub=async(data)=>{
  
    //  const option={
    //   headers: {
         
    //       'x-access-token': token
    //     }
    //  } 
      try {
          const resoponse = await axios.get(`${BaseUrl}Club/getall?page_no=1&page_size=11`,data)
          return resoponse.data
      } catch (error) {
        console.error(error) 
        throw error;   
      }
  }