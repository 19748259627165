import React, { useState } from 'react';
import "./SettingPage.css";
import OverviewSetting from '../settings/settings components/overviewSection/OverviewSetting';
import Sport from '../settings/settings components/Sport';
import Billing from '../settings/settings components/Billing/Billing';
import NavigationBar from '../../../components/Header/Header';
import { Link } from 'react-router-dom';

const SettingPage = () => {
    const [selectedSection, setSelectedSection] = useState("overview");

    return (
        <>
            <NavigationBar />
            <div className="dashboard-body">
                <div className="position-relative">
                    <div className="earning-container container p-1" >
                        <ul className="navbar-nav text-align-center txtclr nav_align">
                            <li className="nav-item"> 
                                <Link
                                to="/settings/overview"
                                    className={`nav-link ${selectedSection === "overview" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("overview")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Overview
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                to="/settings/sport"
                                    className={`nav-link ${selectedSection === "Sport" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("Sport")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Sport
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                to="/settings/billing"
                                    className={`nav-link ${selectedSection === "Billing" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("Billing")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Billing
                                </Link>
                            </li>
                        </ul>
                    </div>

                    <div id="overview" className="wrapper">
                        <div className="job-list-one style-two position-relative mb-20">
                            {selectedSection === "overview" && <OverviewSetting />}
                            {selectedSection === "Sport" && <Sport />}
                            {selectedSection === "Billing" && <Billing />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SettingPage;
