import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import './Teampage.css';
import "./UserModal.css"

const UserTypeModal = ({ show, handleClose, handleSave }) => {
  const initialValues = {
    "User Name": "",
    "Email Id": "",
    "Work Id": "",
    "Role": ""
  };

  const validationSchema = Yup.object().shape({
    "User Name": Yup.string().required('Name is required'),
    "Email Id": Yup.string().email('Invalid email address').required('Email is required'),
    "Work Id": Yup.string().required('Work Id is required'),
    "Role": Yup.string().required('Role is required'),
  });

  return (
    <Modal show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='titlename'>Add New User Type</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values);
            handleClose();
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formUserName">
                <Form.Label>User Type Name <sup style={{color:"red"}}>*</sup></Form.Label>
                <Field type="text" name="User Name" className="form-control" />
                <ErrorMessage name="User Name" component="div" className="error-message" style={{color:"red"}} />
              </Form.Group>
              
                <div className='tableRow'>
                  <p> Access The Personal Information</p>
                  <span className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider sliders"></span>
                    </label>
                  </span>
                </div>
                <div className='tableRow'>
                  <p>Access To technical Information</p>
                  <div className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className='tableRow'>
                  <p> Reels</p>
                  <div className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className='tableRow'>
                  <p>How Many Searched For Me</p>
                  <div className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className='tableRow'>
                  <p>Who Searched For Me</p>
                  <div className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
                <div className='tableRow'>
                  <p>Demographics</p>
                  <div className="Switch_btn_wrap">
                    <label className="switch">
                      <input
                        type="checkbox"
                      // checked={user.videoAccess}
                      // onChange={() => handleVideoAccessChange(user.id)}
                      />
                      <span className="slider"></span>
                    </label>
                  </div>
                </div>
              
              <Button className="btn alignbtn" type="submit" disabled={isSubmitting}>
                {isSubmitting ? 'Adding...' : 'ADD'}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default UserTypeModal;
