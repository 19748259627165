import React, { useState } from 'react'
import ThreeDots from "../../../../../../assets/three-dots.svg";
import './Section3.css'
import { Button } from 'react-bootstrap';

const Section3 = () => {
  const [newSub, setNewSub] = useState([
    {
      "id": "1",
      "Status": "Unpaid",
      "Subscription": "Basic",
      "MRR": "$67.25",
    },
    {
      "id": "2",
      "Status": "Active", 
      "Subscription": "Gold",
      "MRR": "$215.36",
    },
    {
      "id": "3",
      "Status": "Trailing",
      "Subscription": "Premium",
      "MRR": "$15.25",
    },
    {
      "id": "4",
      "Status": "Canceled",
      "Subscription": "Gold",
      "MRR": "$1067.25",
    },
    {
      "id": "5",
      "Status": "Active",
      "Subscription": "Basic",
      "MRR": "$50.00"
    },
    {
      "id": "6",
      "Status": "Unpaid",
      "Subscription": "Silver",
      "MRR": "$89.99"
    },
    {
      "id": "7",
      "Status": "Active",
      "Subscription": "Premium",
      "MRR": "$299.99"
    },
    {
      "id": "8",
      "Status": "Canceled",
      "Subscription": "Basic",
      "MRR": "$75.50"
    },
    {
      "id": "9",
      "Status": "Active",
      "Subscription": "Gold",
      "MRR": "$199.99"
    },
    {
      "id": "10",
      "Status": "Trailing",
      "Subscription": "Silver",
      "MRR": "$25.00"
    },
    {
      "id": "11",
      "Status": "Active",
      "Subscription": "Basic",
      "MRR": "$59.99"
    },
    {
      "id": "12",
      "Status": "Canceled",
      "Subscription": "Premium",
      "MRR": "$499.99"
    }

  ]);
  const [chunSub, setChunSub] = useState([
    {
      "id": "1",
      "Status": "Unpaid",
      "Subscription": "Basic",
      "MRR": "$67.25",
    },
    {
      "id": "2",
      "Status": "Active",
      "Subscription": "Gold",
      "MRR": "$215.36",
    },
    {
      "id": "3",
      "Status": "Trailing",
      "Subscription": "Premium",
      "MRR": "$15.25",
    },
    {
      "id": "4",
      "Status": "Canceled",
      "Subscription": "Gold",
      "MRR": "$1067.25",
    },
    {
      "id": "5",
      "Status": "Active",
      "Subscription": "Basic",
      "MRR": "$50.00"
    },
    {
      "id": "6",
      "Status": "Unpaid",
      "Subscription": "Silver",
      "MRR": "$89.99"
    },
    {
      "id": "7",
      "Status": "Active",
      "Subscription": "Premium",
      "MRR": "$299.99"
    },
    {
      "id": "8",
      "Status": "Canceled",
      "Subscription": "Basic",
      "MRR": "$75.50"
    },
    {
      "id": "9",
      "Status": "Active",
      "Subscription": "Gold",
      "MRR": "$199.99"
    },
    {
      "id": "10",
      "Status": "Trailing",
      "Subscription": "Silver",
      "MRR": "$25.00"
    },
    {
      "id": "11",
      "Status": "Active",
      "Subscription": "Basic",
      "MRR": "$59.99"
    },
    {
      "id": "12",
      "Status": "Canceled",
      "Subscription": "Premium",
      "MRR": "$499.99"
    }

  ]);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case ' Active ':
        return '#0E9767';
      case 'Trailing':
        return '#6D64CB';
      case 'Canceled':
        return '#D93F21';
      case 'Unpaid':
        return '#FCD19C';
      default:
        return '#0E9767'; 
    }
  };

  return (
    <div className="section_wrap">
      <div className="d-flex flex-column flex-lg-row gap-4">
        <div className="card cardarea mb-2 flex-grow-1">
          <div className="usermanagement_chart_header">
            <div className="row">
              <h6 className="col-4">New Subscription</h6>
              <div className="col-6 position-relative">
                <input
                  type="text"
                  className="form-control text-strat search-input rounded-pill searchbox"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <div className="col-2">
                <div className="d-flex gap_555">
                  <img src={ThreeDots} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="table_body_wrap">
            <table className="table table-borderless">
              <thead className='table-strip table-success'>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Subscription</th>
                  <th>MRR</th>
                </tr>
              </thead>
              <tbody>
                {newSub.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <Button style={{ background: getStatusColor(user.Status) }} className='status_color'>
                        {user.Status}
                      </Button>
                    </td>
                    <td>{user.Subscription}</td>
                    <td>{user.MRR}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-17px align-items-center">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">Previous</span>
                </a>
              </li>
              <li className="page-item count_btn_wrap active">
                <a className="page-link clr" href="#">
                  1
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <div className="card cardarea flex-grow-1">
          <div className="usermanagement_chart_header">
            <div className="row align-items-center">
              <h6 className="col-4">Churned Subscription</h6>
              <div className="col-6 position-relative">
                <input
                  type="text"
                  className="form-control text-strat search-input rounded-pill searchbox"
                  placeholder="Search here"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <i className="bi bi-search"></i>
                </span>
              </div>
              <div className="col-2">
                <div className="d-flex align-items-center gap_555">
                  <img src={ThreeDots} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="table_body_wrap">
            <table className="table table-borderless">
              <thead className='table-strip table-success'>
                <tr>
                  <th>ID</th>
                  <th>Status</th>
                  <th>Subscription</th>
                  <th>MRR</th>
                </tr>
              </thead>
              <tbody>
                {chunSub.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>
                      <Button style={{ background: getStatusColor(user.Status) }} className='status_color'>
                        {user.Status}
                      </Button>
                    </td>
                    <td>{user.Subscription}</td>
                    <td>{user.MRR}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center gap-17px align-items-center">
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">Previous</span>
                </a>
              </li>
              <li className="page-item count_btn_wrap active">
                <a className="page-link clr" href="#">
                  1
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item count_btn_wrap">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" aria-label="Next">
                  <span aria-hidden="true">Next</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
}

export default Section3;
