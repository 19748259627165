import React from "react";
import { useDispatch } from "react-redux";
import { fetchAllUser, fetchdeleteUser } from "../../../Redux/Slice/UserSlice";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

const DeleteModal = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const id = user?._id;

  const handleDeleteUser = async () => {
    try {
      if (!id) {
        console.error("Delete operation failed: No ID provided.");
        return;
      }

      const res = await dispatch(fetchdeleteUser(id));
      onClose(); // Close the modal if deletion is successful

      const { isSuccess } = res.payload;

      console.log(isSuccess);

      if (isSuccess) {
        await dispatch(fetchAllUser());
      } else {
        console.error("Delete operation failed: Unsuccessful deletion.");
      }
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };


  return (
    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">Delete User Confirmation</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}></button>
          </div>
          <div className="modal-body">
            Are you sure you want to delete user "{user?.Name}"?
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>Close</button>
            <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={handleDeleteUser}>Delete</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteModal;

// delete icon
{/* <svg
  xmlns="http://www.w3.org/2000/svg"
  width="16"
  height="16"
  fill="red"
  className="bi bi-trash"
  viewBox="0 0 16 16"
>
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
</svg> */}

// view icon
{/* <svg
xmlns="http://www.w3.org/2000/svg"
width="16"
height="16"
fill="currentColor"
className="bi bi-eye-slash"
viewBox="0 0 16 16"
>
<path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
<path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
<path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
</svg> */}