import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import MainLogo from "../../assets/main-logo.svg";
import ProfilePicture from "../../assets/profile-pic.svg";
import "./Header.css";

const NavigationBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => setIsCollapsed(!isCollapsed);

  return (
    <Nav className="navbar navbar-expand-lg navbar-light bg-light shadow-sm border-bottom">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img src={MainLogo} alt="Logo" width="56" height="33" />
        </a>
        <button
          className={`navbar-toggler ${isCollapsed ? 'collapsed' : ''}`}
          type="button"
          onClick={handleToggle}
          aria-controls="navbarNav"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${!isCollapsed ? 'show' : ''}`} id="navbarNav">
          <ul className="mb-2 nav_center" style={{ width: "100%" }}>
            <li className="nav-item">
              <a
                className="nav-link btn btn-success text-auto rounded-pill px-4 py-2 home"
                exact
                href="/"
                activeClassName="active"
              >
                <button className="home">Home</button>
              </a>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link btn btn-success rounded-pill px-4 py-2 ms-3"
                to="/finance"
                activeClassName="active"
              >
                <button className="finance">Finance</button>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link btn btn-success rounded-pill px-4 py-2 ms-3"
                to="/team"
                activeClassName="active"
              >
                <button className="team">Team</button>
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link btn btn-success rounded-pill px-4 py-2 ms-3"
                to="/settings"
                activeClassName="active"
              >
                <button className="setting">Settings</button>
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="navbar_right_profile_options d-flex align-items-center gap-16px">
          <div className="profile_options_left_dp">
            <img src={ProfilePicture} alt="Profile" />
          </div>
          <div className="profile_options_Right">
            <h6>Craig Torff</h6>
            <p>Owner</p>
          </div>
        </div>
      </div>
    </Nav>
  );
};

export default NavigationBar;
