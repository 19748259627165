import React, { useState } from 'react';
import Userlogo from '../../../../../assets/logo/user_logo.png';
import Userlogo1 from '../../../../../assets/logo/Group 1.png';
import Userlogo2 from '../../../../../assets/logo/Group 2.png';
import Userlogo3 from '../../../../../assets/logo/Group 3.png';
import Userlogo4 from '../../../../../assets/logo/Group 4.png';
import discountLogo from '../../../../../assets/logo/tdesign_discount-filled.png';
import UserTypeModal from './overviewModal/UserTypeModal'
import "./OverviewSetting.css"
import CoupanModal from './overviewModal/CoupansModal';
const ModalWrapper = ({ showModal, handleClose, handleSave }) => {
  return (

    <UserTypeModal
      show={showModal}
      handleClose={handleClose}
      handleSave={handleSave}
    />
  
  );
};
const CoupanModalWrapper = ({ showModal, handleClose, handleSave }) => {
  return (

   
    <CoupanModal
    show={showModal}
    handleClose={handleClose}
    handleSave={handleSave}
  />
  );
};
const OverviewSetting = () => {
  const [showModal, setShowModal] = useState(false);
  const [copanModal,setCoupanModal]= useState(false);


  const handleAddMemberClick = () => {
    setShowModal(true);
   
  };
  const handleCoupanClick = () => {
    
    setCoupanModal(true);
  };
  const handleModalClose = () => {
    setShowModal(false);
    setCoupanModal(false);

  };

  const [couponData, setCouponData] = useState([
    {
      "Name": "Sub-7",
      "CODE": "SUB7-001",
      "Discount": "15%",
      "DiscountName": "Summer Sale",
      "Status": "Available",
      "Validity": "Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-002",
      "Discount": "20%",
      "DiscountName": "Back to School",
      "Status": "Unavailable",
      "Validity": "Not Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-003",
      "Discount": "10%",
      "DiscountName": "Spring Discount",
      "Status": "Available",
      "Validity": "Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-004",
      "Discount": "25%",
      "DiscountName": "Holiday Special",
      "Status": "Available",
      "Validity": "Not Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-005",
      "Discount": "30%",
      "DiscountName": "End of Year Sale",
      "Status": "Available",
      "Validity": "Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-006",                                                                                         
      "Discount": "15%",
      "DiscountName": "Early Bird Discount",
      "Status": "Unavailable",
      "Validity": "Not Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-007",
      "Discount": "20%",
      "DiscountName": "New Year Sale",
      "Status": "Available",
      "Validity": "Valid",
    },
    {
      "Name": "Sub-7",
      "CODE": "SUB7-008",
      "Discount": "10%",
      "DiscountName": "Flash Sale",
      "Status": "Unavailable",
      "Validity": "Not Valid",
    },
    // Add more objects as needed
  ]);

  const [modulesData] = useState([
    {
      "Modules": "Reels",
    },
    {
      "Modules": "Soccer Matches",
    },
    {
      "Modules": "Players",
    },
    {
      "Modules": "Pricing",
    },
    {
      "Modules": "Discount Coupons",
    },
  ]);


  const StatusTextColor = (status, validity) => {
    if (status === "Available" && validity === "Valid") {
      return "#0E9767"; 
    } else {
      return "#D93F21"; 
    }
  };

  return (
    <>
    <div className="container">
      <div className="main-container">
        <div className="row-lg card classcard">
          <div className="d-flex justify-content-between">
            <h4 className="heading" style={{ marginLeft: "15px" }}>User Type</h4>
            <button className="btn btn-colr" style={{ marginRight: "25px" }} onClick={handleAddMemberClick}> + Add New</button>
          </div>
          <ul className="main-row-chart justify-content-between d-flex d-l-g-block" style={{ marginTop: "20px" }}>
            <li className="gap-10 d-flex justify-content-between m-3">
              <img src={Userlogo} alt="USERS" width="67px" />
              <div>
              <p>Players</p> 
              <h3>50</h3>
              </div>
            </li>
            <li className="gap-10 d-flex justify-content-between m-3">
              <img src={Userlogo1} alt="USERS" width="67px" />
              <div>
              <p>Agents</p>
              <h3>50</h3>
              </div>
            </li>
            <li className="gap-10 d-flex justify-content-between m-3">
              <img src={Userlogo2} alt="USERS" width="67px" />
              <div>
              <p>School</p>
              <h3>50</h3>
              </div>
            </li>
            <li className="gap-10 d-flex justify-content-between m-3">
              <img src={Userlogo3} alt="USERS" width="67px" />
              <div>
              <p>Amateur Club</p>
              <h3>50</h3>
              </div>
            </li>
            <li className="gap-10 d-flex justify-content-between m-3">
              <img src={Userlogo4} alt="USERS" width="67px" />
              <div>
              <p>Professional Club</p>
              <h3>50</h3>
              </div>
            </li>
          </ul>
        </div>
      </div>

        <div className='row main-containers gapp '>
          <div className="col-lg-5 usermanagement_chart_wrap ">
            
                <div className="usermanagement_chart_header">
                  <div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>
                    <h5 className="headings">Module Settings</h5>
                    <button className="btn btn-colr" onClick={handleCoupanClick}>+ Add New</button>
                  </div>
                </div>
                <div className="table_body_wrap new_wrap">
                  <table className="table table-borderless">
                    <thead className='table-strip table-success'>
                      <tr>
                        <th className='contenth'> Module</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modulesData.map((module, index) => (
                        <tr key={index}>
                          <td style={{ textAlign: "left" }}>{module.Modules}</td>
                          <td> 
                          {/* <a className="btn row_end_btns">
                          <i className="fa-light fa-pen-line"></i>
                            </a> */}
                            <a className="btn row_end_btns">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-eye-slash" viewBox="0 0 16 16">
                                <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                                <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                                <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                              </svg>
                            </a>
                            <a className="btn row_end_btns">
                              <img src={discountLogo} alt="" />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
             
          </div>
          <div className="col-6 usermanagement_chart_wrap ">
            
                <div className="usermanagement_chart_header">
                  <div className="d-flex justify-content-between align-items-center"style={{width:'100%'}} >
                    <h5 className="headings">Coupons</h5>
                    <button className="btn btn-colr" onClick={handleCoupanClick}>+ Add New</button>
                  </div>
                </div>
                <div className="table_body_wrap new_wrap">
                  <table className="table table-borderless">
                    <thead className='table-strip table-success'>
                      <tr>
                        <th>Name</th>
                        <th>CODE</th>
                        <th>Discount %</th>
                        <th>Status</th>
                        <th>Validity</th>
                      </tr>
                    </thead>
                    <tbody>
                      {couponData.map((coupon, index) => (
                         <tr key={index}>
                         <td>{coupon.Name}</td>
                         <td>{coupon.CODE}</td>
                         <td>{coupon.Discount}</td>
                         <td style={{ color: StatusTextColor(coupon.Status, coupon.Validity) }}>{coupon.Status}</td>
                         <td style={{ color: StatusTextColor(coupon.Status, coupon.Validity) }}>{coupon.Validity}</td>
                       </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              
          </div>
      </div>
    </div>

      {showModal && (
        <ModalWrapper
          showModal={showModal}
          handleClose={handleModalClose}
          handleSave={() => {} /* Add save functionality here if needed */}
        />
     
      )}
       {copanModal && (
      
        <CoupanModalWrapper
        showModal={copanModal}
        handleClose={handleModalClose}
        handleSave={() => {} }
      />
      )}
    </>
  );
};

export default OverviewSetting;
