import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CreateTeamMember, getAllTeamMember , getbyidTeamMember, deleteTeamMember, searchTeamMember } from "../Api/TeamApi"


const initialState = {
  
  team: '',
  page: 1,
  loading: false,
  error: null,
};

export const createTeamMember = createAsyncThunk(
  'team/createTeamMember',
  async (values) => {
    console.log('valuesvalues', values)
    try {
      const response = await CreateTeamMember(values);
      const imageid = response.data.data._id
      // if (response.data.isSuccess) {
      //     createProfile({ imageid, avatarFil })
      // }
      return response.data;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }
);



export const fetchAllTeamMember = createAsyncThunk(
  'team/fetchAllTeamMember',
  async (data) => {
    try {
      const response = await getAllTeamMember(data);
      console.log(response, "121212aaaaaaa")
      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const fetchbyidTeamMember = createAsyncThunk(
  'team/fetchbyidTeamMember',
  async (data) => {
   
    try {
      const response = await getbyidTeamMember(data);
      return response.data
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);
export const fetchdeleteTeamMember = createAsyncThunk(
  'team/fetchdeleteTeamMember',
  async (id) => {
    try {
      const response = await deleteTeamMember(id);
      console.log(response, "Deleteusers")

      return response.data;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const fetchsearchTeamMember = createAsyncThunk(
  'team/fetchsearchTeamMember',
  async (data) => {
    try {
      const response= await searchTeamMember(data);
      return response ;
    } catch (error) {
      console.error("Error creating user:", error);
      throw error;
    }
  }
);



const teamSlice = createSlice({
  name: 'teamSlice',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    }
    
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeamMember.fulfilled, (state, action) => {
        state.loading = false;
        state.team = action.payload;
      })
      .addCase(createTeamMember.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(createTeamMember.rejected, (state, action) => {
        state.loading = false;
        state.error = "error";
      })
      // get all users----------

      .addCase(fetchAllTeamMember.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAllTeamMember.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.team = action.payload;
      })
      .addCase(fetchAllTeamMember.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })

            // usergetbyid-----
     .addCase(fetchbyidTeamMember.fulfilled, (state, action) => {
        state.team = action.payload;
        state.loading = false;
      })

    // delete----------

    .addCase(fetchdeleteTeamMember.fulfilled, (state, action) => {
      state.loading = false;
    })

  // search

  .addCase(fetchsearchTeamMember.fulfilled,(state,action)=>{
    state.team=action.payload;
    state.loading = false;
  })



},
    });
export const { setPage } = teamSlice.actions
export default teamSlice.reducer;
