import React from 'react'
import { BrowserRouter,Route, Routes } from 'react-router-dom';
import Home from '../pages/dashboard/home/Home';
import NavigationBar from '../components/Header/Header';
import FinancePage from '../pages/dashboard/FinancePage/FinancePage';
import SettingPage from '../pages/dashboard/settings/SettingPage';
import TeamPage from '../pages/dashboard/Team/TeamPage';
import ViewUserDetails from '../components/dashboard/user-details/ViewUserDetails';
import UserManagement from '../components/dashboard/UserManagement';
import Overview from '../pages/dashboard/FinancePage/Components/Overview/Overview';
import Payments from '../pages/dashboard/FinancePage/Components/payments/Payments';
import Subscription from '../pages/dashboard/FinancePage/Components/Subscription/Subscription';
import Customer from '../pages/dashboard/FinancePage/Components/Customers/Customers';
import Sport from '../pages/dashboard/settings/settings components/Sport';
import Billing from '../pages/dashboard/settings/settings components/Billing/Billing';
import OverviewSetting from '../pages/dashboard/settings/settings components/overviewSection/OverviewSetting';
import Soccer from '../pages/dashboard/settings/settings components/soprtsSection/Soccer';
import Basketball from '../pages/dashboard/settings/settings components/soprtsSection/BasketBall';
import FootBall from '../pages/dashboard/settings/settings components/soprtsSection/FootBall';
import IndoorSoccer from '../pages/dashboard/settings/settings components/soprtsSection/IndoorSoocer';
import VolleBall from '../pages/dashboard/settings/settings components/soprtsSection/VolleBall';
const Routing = () => {

  return (
   <>
   <BrowserRouter>
     <Routes>
       <Route exact path="/" element={<Home />} >
     <Route path="/usermanagement" element={<UserManagement/>}/>
     <Route path="/user/:id" element={<ViewUserDetails/>}/>
       </Route>
       <Route path="/finance" element={<FinancePage />} >
     <Route path="/finance/overview" element={<Overview/>}/>
     <Route path="/finance/payments" element={<Payments/>}/>
     <Route path="/finance/subscription" element={<Subscription/>}/>
     <Route path="/finance/customers" element={<Customer/>}/>

       </Route>
       <Route path="/team" element={<TeamPage />} /> 
       <Route path="/settings" element={<SettingPage />} >
     <Route path="/settings/overview" element={<OverviewSetting/>}/>
     <Route path="/settings/sport" element={<Sport />}>
     <Route path="/settings/sport/soccer" element={<Soccer />}/>
     <Route path="/settings/sport/basketball" element={<Basketball />}/>
     <Route path="/settings/sport/football" element={<FootBall />}/>
     <Route path="/settings/sport/indoorsoccer" element={<IndoorSoccer />}/>
     <Route path="/settings/sport/volleyball" element={<VolleBall />}/>
     </Route>
     <Route path="/settings/billing" element={<Billing/>}/>
       </Route>
     </Routes>
    
   </BrowserRouter>
   
   </>
  )
}

export default Routing