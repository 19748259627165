import axios from "axios"
import BaseUrl from "../../utils/Baseurl"


export const CreateSubscription=async(data)=>{
    try {
        const resoponse = await axios.post(`https://mosis-backend.cradle.services/Subscription/create`,data)
        return resoponse
    } catch (error) {
      console.error(error)
      throw error;    
    }
}


export const getAllSubscription=async(data)=>{
  
       
      try {
          const resoponse = await axios.get(`${BaseUrl}Subscription/getall?page_no=1&page_size=11`,data)
          return resoponse.data
      } catch (error) {
        console.error(error) 
        throw error;   
      }
  }