import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './CouponModal.css'

const CoupanModal = ({ show, handleClose, handleSave }) => {
  const initialValues = {
    "User Name": "", 
    "Email Id": "",
    "Work Id": "",
    "Role": ""
  };

  const validationSchema = Yup.object().shape({
    "User Name": Yup.string().required('Full Name is required'),
    "Email Id": Yup.string().email('Invalid email address').required('Email is required'),
    "Work Id": Yup.string().required('Work Id is required'),
    "Role": Yup.string().required('Role is required'),
  });

  return (
    <Modal className='mainmodal' show={show} centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title className='titlename'>Add Team Member</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik 
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSave(values);
            handleClose();
            setSubmitting(false);
          }}
        >
        {({ isSubmitting, handleSubmit }) => (
          <Form className='main_content' onSubmit={handleSubmit}>
            <Form.Group controlId="formUserName">
              <Form.Label>Name of Coupon <sup style={{color:"red"}}>*</sup></Form.Label>
              <Field type="text" name="User Name" className="form-control inputfield" />
              <ErrorMessage name="User Name" component="div" className="error-message" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Code of Coupon <sup style={{color:"red"}}>*</sup></Form.Label>
              <Field type="email" name="Email Id" className="form-control inputfield" />
              <ErrorMessage name="Email Id" component="div" className="error-message" />
            </Form.Group>
            <Form.Group controlId="formWorkId">
              <Form.Label>Discount applicable <sup style={{color:"red"}}>*</sup></Form.Label>
              <Field type="text" name="Work Id" className="form-control inputfield" />
              <ErrorMessage name="Work Id" component="div" className="error-message" />
            </Form.Group>
            <Form.Group controlId="formRole" >
              <Form.Label >Validity <sup style={{color:"red"}}>*</sup></Form.Label>
              <div className='d-flex m-3 datefixer'>
                <span>From
                <Field type="date" name="Role" className="form-control inputfield" />
                </span>
                <span>To
              <Field type="date" name="Role" className="form-control" />
              </span>
              </div>
              <ErrorMessage name="Role" component="div" className="error-message" />

            </Form.Group>
            <br />
            <Button className="btn  alignbtn" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Adding...' : 'ADD'}
            </Button>
          </Form>
        )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CoupanModal;
