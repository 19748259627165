import React from 'react'
import PaymentCharts1 from './Payment part1/paymentChats1'
import Paymentpart2 from './PaymentPart 2/paymentpart2'
import Paymentpart3 from './PaymnetPart 3/Paymentpart3'
import PaymentCrud from './PaymentCrud 4/PaymentCrud'

const Payments = () => {
  return ( 
    <>
    <PaymentCharts1/>
     <Paymentpart2/>
     <Paymentpart3/>
     <PaymentCrud/>
      </>
  )
}

export default Payments