import React, { useState } from 'react';
import ThreeDots from "../../../../../../assets/three-dots.svg";
import { Button } from 'react-bootstrap';
import './PaymentCrud.css'
const PaymentCrud = () => {
  const [couponData, setCouponData] = useState([
    {
      "ID": "01",
      "Status": "Canceled",
      "Description": "Credit Card",
      "Customer": "Kianna Philips",
    },
    {
      "ID": "02",
      "Status": "Successeded",
      "Description": "Credit Card",
      "Customer": "Makenna Kenter",
    },
    {
      "ID": "03",
      "Status": "Successeded",
      "Description": "Credit Card",
      "Customer": "Kadin Dorwart",
    },
    {
      "ID": "04",
      "Status": "Progressing",
      "Description": "Credit Card",
      "Customer": "Ruben Rosser",
    },
    {
      "ID": "05",
      "Status": "Failed",
      "Description": "Credit Card",
      "Customer": "Corey Saris",
    },
  ]);

  const [modulesData, setModulesData] = useState([
    {
      "ID": "01",
      "Status": "Canceled",
      "Reason": "Requested By customer",
    },
    {
      "ID": "02",
      "Status": "Successeded",
      "Reason": "Duplicate",
    },
    {
      "ID": "03",
      "Status": "Successeded",
      "Reason": "Requested By customer",
    },
    {
      "ID": "04",
      "Status": "Progressing",
      "Reason": "Duplicate",
    },
    {
      "ID": "05",
      "Status": "Failed",
      "Reason": "Fraudulent",
    },
  ]);

  const getStatusColor = (status) => {
    switch (status) {
      case 'Successeded':
        return '#0E9767';
      case 'Progressing':
        return '#6D64CB';
      case 'Failed':
        return '#ECA1FF';
      case 'Canceled':
        return '#FCD19C';
      default:
        return ''; 
    }
  };

  return (
    <>
      <div className=''>
        <div className='d-flex d-l-g-block main-containers gap-5'>
          <div className="usermanagement_chart_wrap width-50">
              <div className="">
                <div className="usermanagement_chart_header">
                  <div className="d-flex justify-content-between">
                    <h4 className="heading" style={{ marginLeft: "-15px" }}>New Payments</h4>
                    <img className='btn' src={ThreeDots} alt="" />
                  </div>
                </div>
                <div className="table_body_wrap">
                  <table className="table table-borderless">
                    <thead className='table-strip table-success'>
                      <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Description</th>
                        <th>Customer</th>
                      </tr>
                    </thead>
                    <tbody className='mx-3'>
                      {couponData.map((user, index) => (
                        <tr key={index}>
                          <td>{user.ID}</td>
                          <td> <Button style={{ background: getStatusColor(user.Status) }} className='status_color'>{user.Status}</Button></td>
                          <td>{user.Description}</td>
                          <td>{user.Customer}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
          <div className=" usermanagement_chart_wrap wrapeers width-50">
              <div className="">
                <div className="usermanagement_chart_header">
                  <div className="d-flex justify-content-between">
                    <h4 className="heading" style={{ marginLeft: "-15px" }}>New Refunds</h4>
                    <img className='btn' src={ThreeDots} alt="" />
                  </div>
                </div>
                <div className="table_body_wrap">
                  <table className="table table-borderless">
                    <thead className='table-strip table-success'>
                      <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Reason</th>
                      </tr>
                    </thead>
                    <tbody className='mx-3'>
                      {modulesData.map((user, index) => (
                        <tr key={index}>
                          <td>{user.ID}</td>
                          <td > <Button style={{ background: getStatusColor(user.Status) }} className=' status_color'>{user.Status} </Button></td>
                          <td>{user.Reason}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCrud;
