import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const SubscriptionFlowChart = () => {
  const initialLabel = 'Gold';
  const initialPercentage = 65; 
  const [selectedLabel, setSelectedLabel] = useState(initialLabel);
  const [series, setSeries] = useState([initialPercentage]);

  const options = {
    chart: {
      // height: 400,
      type: 'radialBar',
      offsetY: 0
    },
    plotOptions: {
      radialBar: {
        startAngle: -85,
        endAngle: 85,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: -16
          },
          value: {
            offsetY: 6,
            fontSize: '22px',
            color: undefined,
            formatter: function (val) {
              return val + "%";
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.4,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.2,
        stops: []
      },
    },
    stroke: {
      dashArray: 10
    },
    labels: [selectedLabel],
    colors: [getColorByLabel(selectedLabel)],
  };

  function getColorByLabel(label) {
    switch (label) {
      case 'Gold':
        return 'rgb(138,240,172)';
      case 'Basic':
        return 'rgb(188,182,255)';
      case 'Premium':
        return 'rgb(255,173,95)';
      default:
        return '#000000';
    }
  }

  const handleLabelClick = (label, percentage) => {
    setSelectedLabel(label);
    setSeries([percentage]);
  };

  return (
    <div className="subscription-chart">
      <div className="home_card">
        <div className="home_card_left">
          <h6>Total Subscribers </h6>
          <h5>5000</h5>
        </div>

        <div className="home_card_right ">
        <Chart options={options} series={series}  type="radialBar"  />

         
          <div className="home_card_right_info d-flex">
            
            <div className="card_right_info_left width-50">
              
              <div className="chart_reading_info d-flex justify-content-between" onClick={() => handleLabelClick('Basic', 35)}>
                <h6>
                  <span className="home_chart_color_btn basic" style={{ backgroundColor: 'rgb(188,182,255)' }}></span>{" "}
                  Basic
                </h6>
                <p>35%</p>
              </div>
              <div className="chart_reading_info d-flex justify-content-between" onClick={() => handleLabelClick('Gold', 65)}>
                <h6>
                  <span className="home_chart_color_btn gold" style={{ backgroundColor: 'rgb(138,240,172)' }}></span>{" "}
                  Gold
                </h6>
                <p>65%</p>
              </div>
            </div>
            <div className="card_right_info_right width-50">
              <div className="chart_reading_info d-flex justify-content-between" onClick={() => handleLabelClick('Premium', 25)}>
                <h6>
                  <span className="home_chart_color_btn premium" style={{ backgroundColor: 'rgb(255,173,95)' }}></span>{" "}
                  Premium
                </h6>
                <p>25%</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionFlowChart;
