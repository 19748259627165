import React from 'react'
import './assets/css/App.css';
import { BrowserRouter as Router, Route, Routes, BrowserRouter } from 'react-router-dom';
// import Home from './pages/dashboard/home/Home';
// import NavigationBar from './components/Header/Header';
// import FinancePage from './pages/dashboard/FinancePage/FinancePage';
// import SettingPage from './pages/dashboard/settings/SettingPage';
// import TeamPage from './pages/dashboard/Team/TeamPage';
import Routing from './Routes/Routing';



const App = () => {
  return (
    <>
    <Routing/>
    </>
  )
}

export default App
