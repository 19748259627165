import React, { useEffect, useState } from "react";
import './Teampage.css';
import Userlogo from '../../../assets/logo/user_logo.png'
import Userlogo1 from '../../../assets/logo/Frame 47971.png'
import Userlogo2 from '../../../assets/logo/Frame 47973.png'
import AddMemberModal from "./AddMemberModal";
import NavigationBar from "../../../components/Header/Header";
import TeamDeleteModal from "./DeleteTeamMember";
import { fetchAllTeamMember, fetchsearchTeamMember } from "../../../Redux/Slice/TeamSlice";
import { useDispatch, useSelector } from "react-redux";


const ModalWrapper = ({ showModal, handleClose, handleSave }) => {

  return (
    <AddMemberModal
      show={showModal}
      handleClose={handleClose}
      handleSave={handleSave}
    />
  );
};

const TeamPage = () => {
  const [searchTerm, setSearchTerm] = useState([]);
  // const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState(null); 
  const [showModal, setShowModal] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [teamToDelete, setTeamToDelete] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);


  const dispatch = useDispatch();
  const Team = useSelector((state) => state?.team?.team);
  const TeamData = Team ;
  console.log(TeamData, "newTeamData---")



  useEffect(() => {
    dispatch(fetchAllTeamMember())
      .catch((error) => console.error('Error fetching team members:', error));
  }, [dispatch]);
  
  // In your component, check if TeamData is an array before setting it to state
  useEffect(() => {
    if (Array.isArray(Team)) {
      setTeamData(Team);
    } else {
      console.error('Team data is not an array:', Team);
    }
  }, [Team]);

  

  const handleSearchChange = (e) => {
    const searchValue = e.target.value;

    if (searchValue.trim() === '') {
      dispatch(fetchAllTeamMember());
    } else {
      dispatch(fetchsearchTeamMember(searchValue));

    }
    setSearchTerm(searchValue);

    console.log("valuecheck for search", searchValue)
  };
  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const filteredUsers = TeamData.filter(
  //   (user) =>
  //     user["User Name"].toLowerCase().includes(searchTerm.toLowerCase()) ||
  //   user["Email Id"].toLowerCase().includes(searchTerm.toLowerCase())
  // );




  const handleDeleteIconClick = (team) => {
    setTeamToDelete(team);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    console.log("close modal")
    setShowDeleteModal(false);
    setTeamToDelete(null);
  };


  const handleViewDetails = (team) => {
    setSelectedUser(team);
  };

  const handleAddMemberClick = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleSaveMember = (newTeam) => {
    setTeamData([...teamData, { ...newTeam, "sr no.": teamData.length + 1 }]);
  };



  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <NavigationBar className="navbar" />
<div className="container">
<div className="main-container ">
        <div className="row-lg card  badge-container m-5">
          <ul className="main-row-chart" >
           
            <li className="m-3 first-col text-start" >
              <h5 >Lorem ipsum dolor sit amet consectetur.</h5>
              <p>Lorem ipsum dolor sit amet consectetur.</p>
              <button className="btn btn-colorr" onClick={handleAddMemberClick} >+ Add Member</button>
            </li>
            
<div className="d-flex d-l-g-block">
            <li className="  m-3 d-flex align-items-center gap-10">
            <img src={Userlogo} alt="USERS" width="67px" />
              <div>
              <p>Total Members</p>
              <h3>50</h3>
              </div>
              
            </li>
            <li className="  m-3 d-flex align-items-center gap-10">
              <img src={Userlogo1} alt="USERS" width="67px" />
              <div>
              <p>Total Members</p>
              <h3>50</h3>
              </div>
            </li>
            <li className="  m-3 d-flex align-items-center gap-10">
              <img src={Userlogo2} alt="USERS" width="67px" />
              <div>
              <p>Total Members</p>
              <h3>50</h3>
              </div>
            </li>
</div>
          </ul>
        </div>
      </div>

      <div className=" team_chart_wrap m-5">
        <div className="row-lg">
          <div className="col-md-12">
            <div className="team_chart_header">
              <div className="row align-items-center header_Item">
                <h4 className="col-3">Team Management</h4>
                <div className="col-6 position-relative">
                  <input
                    type="text"
                    className="form-control text-strat search-input rounded-pill"
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    />
                  <span className="search-icon">
                    <i className="bi bi-search"></i>
                  </span>
                </div>
                <div className="col-3">
                  <div className="justify-content-end d-flex align-items-center gap-20">
                    <a>
                      Select All {"  "}
                      <input type="checkbox" />
                    </a>
                    <a>
                      Delete
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="red"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="table_body_wrap">
              <table className="table table-striped ">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th>User Name</th>
                    <th>Work Id</th>
                    <th>Join Date</th>
                    <th>Email ID</th>
                    <th>Role</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {Array.isArray(teamData) && teamData.map((items, index) => (
                    <tr key={items._id}>
                      <td>{index + 1}</td>
                      <td>{items.Name}</td>
                      <td>{items.workId}</td>
                      <td>{formatDate(items.created_at)}</td>
                      <td>{items.email}</td>
                      <td>{items.role}</td>
                      <td>
                        <a
                          className="btn row_end_btns"
                          onClick={() => handleViewDetails(items)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-eye-slash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                            <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                          </svg>
                        </a>
                        <a className="btn row_end_btns"

                          href="/"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal"
                          onClick={() => handleDeleteIconClick(items)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="red"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                          </svg>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination */}
            <nav aria-label="Page navigation example">
              <ul className="pagination justify-content-center gap-17px align-items-center">
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">Previous</span>
                  </a>
                </li>
                <li className="page-item count_btn_wrap active">
                  <a className="page-link clr" href="#">
                    1
                  </a>
                </li>
                <li className="page-item count_btn_wrap">
                  <a className="page-link" href="#">
                    2
                  </a>
                </li>
                <li className="page-item count_btn_wrap">
                  <a className="page-link" href="#">
                    3
                  </a>
                </li>
                <li className="page-item">
                  <a className="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
</div>
     
      <ModalWrapper
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={(newTeam) => {
          setTeamData([...teamData, { ...newTeam, "sr no.": teamData.length + 1 }]);
        }}
      />

      <TeamDeleteModal team={teamToDelete} onClose={() => handleCloseDeleteModal()} />

    </>
  );
};

export default TeamPage;
