import React from 'react'
import CustomerTable from './CustomerTable'
import CustomerCharts from './CustomerCharts'

const Customer = () => {
  return (
   <>
   {/* <div></div> */}
   <CustomerCharts/>
   <CustomerTable/>
   </>
  )
}

export default Customer