import React from 'react'
import UserManagement from '../../../components/dashboard/UserManagement'
import NavigationBar from '../../../components/Header/Header'

const Home = () => {
  return (
   <>
   <UserManagement/>
   </>
  )
}

export default Home