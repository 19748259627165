import React from 'react'
import PaymentProgressBarChart from './PaymentProgressBar'
import RefundProgressBarChart from './RefundProgressBar'
import ThreeDots from "../../../../../../assets/three-dots.svg";
import CalenderIcon from "../../../../../../assets/calender-icon.svg";
import { Card } from 'react-bootstrap';
import './paymentPart3.css'

const Paymentpart3 = () => {
  return (
    <>
     
        <div className="row d-flex justify-content-between d-l-g-block mb-5">
          <div className="width-50 mb-5">
            <div className="card cardsize">
            <div className="subscription_card_body_wrap">
              <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
                <h4>Payments By Customers </h4>
                <div className="d-flex align-items-center gap-30px">
                  <img src={CalenderIcon} alt="" />
                  <img className='btn' src={ThreeDots} alt="" />
                </div>
              </div>

              <div className="progress-bar-items new_wrap1">
                <PaymentProgressBarChart className="newbar_item"/>
              </div>
            </div></div>
          </div>
          <div className="width-50">
          <div className="card cardsize">
            <div className="subscription_card_body_wrap ">
              <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
                <h4>Refunds By Customers </h4>
                <div className="d-flex align-items-center gap-30px">
                  <img src={CalenderIcon} alt="" />
                  <img src={ThreeDots} alt="" />
                </div>
              </div>

              <div className="progress-bar-items new_wrap1 ">
                <RefundProgressBarChart  className="newbar_item"/>
              </div>
            </div>
            </div>
          </div>
        </div>
      
    </>
  )
}

export default Paymentpart3