import { configureStore } from '@reduxjs/toolkit'
import UserSlice from '../Redux/Slice/UserSlice'
import ClubSlice from '../Redux/Slice/ClubSlice'
import AgentSlice from '../Redux/Slice/AgentSlice'
import SubscriptioSlice from '../Redux/Slice/SubscriptioSlice'
import TeamSlice from '../Redux/Slice/TeamSlice'
import ReelsSlice from '../Redux/Slice/ReelsSlice'



export const store = configureStore({
  reducer: {
    users:UserSlice,
    clubs:ClubSlice,
    agents:AgentSlice,
    subscriptions:SubscriptioSlice,
    team:TeamSlice,
    Reel:ReelsSlice,
  },
})