import React from 'react'
import Section1 from './common/Section1'
import Section2 from './common/Section2'
import Section3 from './common/Section3'

const Subscription = () => {
  return (
   <>
   <Section1/>
   <Section2/>
   <Section3/>
   </>
  )
}

export default Subscription