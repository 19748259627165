import React from "react";
// import { Container, Row, Col, Badge } from "react-bootstrap";
import ProgressGold from "../../assets/progress-gold.svg";
import TotalChart from "../../assets/total-chart.svg";
import SchoolIcon from "../../assets/school-icon.svg";
import AgentsIcon from "../../assets/agents-icon.svg";
import ClubsIcon from "../../assets/clubs-icon.svg";
import PlayersIcon from "../../assets/players-icon.svg";
import "./Dashboard.css";
import SubscriptionFlowChart from "./common/SubscriptionFlowChart";
import ContentChart from "./common/ContentChart";

const Stats = () => {
 
  const matchValue = 80;
  const reelsValue = 20;
  return (
    <>
      <div className="home_charts_main_wrap">
        <div className="m-5 maincontainerss">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
                <div className="col-md-8">
                <SubscriptionFlowChart />
                </div>
                <div className="col-md-4">
                <ContentChart match={matchValue} reels={reelsValue} />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="total_contents_wrap">
                <div className="card_total_content home_card d-flex align-items-center d_block">
                  <div className="content_row border-right">
                    <div className="logo_wrap school_bg">
                      <img src={SchoolIcon} alt="" />
                    </div>
                    <div className="xyz">
                      <p>Total Schools</p>
                      <h6>53</h6>
                    </div>
                  </div>{" "}
                  <div className="content_row">
                    <div className="logo_wrap agent_bg">
                      <img src={AgentsIcon} alt="" />
                    </div>
                    <div className="xyz">
                      <p>Total Agents</p>
                      <h6>53</h6>
                    </div>
                  </div>
                </div>
                <div className="card_total_content home_card d-flex align-items-center d_block">
                  <div className="content_row border-right">
                    <div className="logo_wrap club_bg">
                      <img src={ClubsIcon} alt="" />
                    </div>
                    <div className="xyz">
                      <p>Total Clubs</p>
                      <h6>53</h6>
                    </div>
                  </div>{" "}
                  <div className="content_row">
                    <div className="logo_wrap player_bg">
                      <img src={PlayersIcon} alt="" />
                    </div>
                    <div className="xyz">
                      <p>Total Players</p>
                      <h6>53</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Stats;
