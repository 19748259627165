import React, { useState } from 'react';
import Chart from 'react-apexcharts';

const SubChart2 = () => {
  const [options, setOptions] = useState({
    chart: {
      width: 580,
      type: 'donut',
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 270,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'gradient',
    },
    legend: {
      formatter: function (val, opts) {
        return val + " - " + opts.w.globals.series[opts.seriesIndex];
      },
    },
    labels: ['Past Due', 'Active', 'Trialing', 'Unpaid', 'Canceled'],
    colors: [
      'rgb(166, 223, 255)',  // Past Due
      'rgb(182, 217, 87)',   // Active
      'rgb(217, 152, 203)',  // Trialing
      'rgb(250, 195, 100)',  // Unpaid
      'rgb(100, 175, 177)',  // Canceled
    ],
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: 'bottom',
        },
      },
    }],
  });

  const [series, setSeries] = useState([453, 449, 341, 176, 80]);

  return (
    <div className="card_inner_body_wrap">
      <div className="row">
        <Chart options={options} series={series} type="donut" height={280} />
      </div>
    </div>
  );
};

export default SubChart2;
