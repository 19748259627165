import React, { useState } from 'react';
// import "./ProgressBar.css";


const RefundProgressBarChart = () => {
    const [progressValues, setProgressValues] = useState({
      ZaireWorkman: 2171.14,
      AhmadMango: 1702.14,
      MarleyBator: 1512.36,
      DesiraeRosser:2171.14,
      SkylarKorsgaard: 1702.14,
      KadinEkstromBothman: 1512.36,
    });

    const maxProgress = Math.max(progressValues.ZaireWorkman, progressValues.AhmadMango, progressValues.MarleyBator,progressValues.DesiraeRosser,progressValues.SkylarKorsgaard,progressValues.KadinEkstromBothman);

    const Progress = ({ value, color }) => {
        const done = (value / maxProgress) * 50;

        // Construct backgroundColor from color object
        const backgroundColor = `rgb(${color.red}, ${color.green}, ${color.blue})`;

        const style = {
            opacity: 1,
            width: `${done}%`,
            backgroundColor: backgroundColor,
            borderRadius: '50px'
        };

        return (
            <div className="progress">
                <div className="progress-done" style={style}>
                </div>
            </div>
        );
    };

    return (
        <div className='main_progressbar'>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label" >
                    <label htmlFor="monthly">Zaire Workman:</label>
                    <span className="progress-bar-value" >
                        ${progressValues.ZaireWorkman}
                    </span>
                </div>
                <Progress value={progressValues.ZaireWorkman} color={{ red: 30, green: 126, blue: 15 }} />
            </div>

            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="daily">Ahmad Mango:</label>
                    <span className="progress-bar-value">
                        ${progressValues.AhmadMango}
                    </span>
                </div>
                <Progress value={progressValues.AhmadMango} color={{ red: 30, green: 126, blue: 15 }} />
            </div>

            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Marley Bator:</label>
                    <span className="progress-bar-value">
                        ${progressValues.MarleyBator}
                    </span>
                </div>
                <Progress value={progressValues.MarleyBator} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Desirae Rosser:</label>
                    <span className="progress-bar-value">
                        ${progressValues.DesiraeRosser}
                    </span>
                </div>
                <Progress value={progressValues.DesiraeRosser} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Skylar Korsgaard:</label>
                    <span className="progress-bar-value">
                        ${progressValues.SkylarKorsgaard}
                    </span>
                </div>
                <Progress value={progressValues.SkylarKorsgaard} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Kadin Ekstrom Bothman:</label>
                    <span className="progress-bar-value">
                        ${progressValues.KadinEkstromBothman}
                    </span>
                </div>
                <Progress value={progressValues.KadinEkstromBothman} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
        </div>
    );
};

export default RefundProgressBarChart;
