import React from 'react';
import ApexCharts from 'react-apexcharts';
import ThreeDots from "../../../../../assets/three-dots.svg";
// import CalenderIcon from "../../../../../../assets/calender-icon.svg";
import CountryMap from "../../../../../assets/country-map.svg";
import './CustomerChart.css'
import CountryChart from './CountryChart'
const CustomerChart = () => {
  const paymentoptions = {
   
    series: [{
      name: "Payments",
      data: [2,5,3,1,4,6]
  }],
    chart: {
      
    height: 350,
    type: 'line',
    zoom: {
      enabled: false
    }
  },
  // dataLabels: {
  //   enabled: false
  // },
  stroke: {
    curve: 'smooth'
  },
  title: {
    // text: 'Product Trends by Month',
    align: 'left'
  },
  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  
  xaxis: {
    categories: [" ","6 May", "11 May", "20 May", "27 May", "31 May"," "],
  }
  };

  var chart = new ApexCharts(document.querySelector("#chart"), paymentoptions);
  chart.render();

 

  

  return (
    <div>
      <div className='row main_container mb-5'>
        <div className='col-12 col-xl-6 card '>
          <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
            <h4>New Customers by date</h4>
            <div className="d-flex align-items-center gap-30px">
              <h5>$71,177.00  </h5>
              <img src={ThreeDots} alt="Three Dots Icon" />
            </div>
          </div>
          {/* <div className="progress-bar-item "> */}
            <ApexCharts options={paymentoptions} series={paymentoptions.series} type="line" height={280} width="100%" className="chartdecor"  />
          {/* </div> */}
        </div>
        {/* ----------second chart--------- */}
        <div className='col-12 col-xl-6 card ' >
        <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
              <h4>Customers by country</h4>
              <div className="d-flex align-items-center gap-30px">
                {/* <img src={CalenderIcon} alt="" /> */}
                <img src={ThreeDots} alt="" />
              </div>
            </div>
          <div className="country_map">
              <CountryChart/>
            </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerChart;
