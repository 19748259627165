import React, { useState } from 'react'
import editLogo from '../../../../../assets/logo/iconamoon_edit-light.svg'
const Soccer = () => {

  const [userData, setUserData] = useState([
    {
      "Name": "Sub-7",
      "Age": "6 year- 7 year",

    },
    {
      "Name": "Sub-9",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Sub-11",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Sub-13",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Sub-15",
      "Age": "6 year- 7 year",
    },
    {
      "Name": "Sub-17",
      "Age": "6 year- 7 year",
    },
    {
      "Name": "Sub-20",
      "Age": "6 year- 7 year",
    },
   
  ]);
  const [seriseData, setSeriseData] = useState([
    {
      "Name": "Serise A",
      "Age": "6 year- 7 year",

    },
    {
      "Name": "Serise B",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Serise C",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Serise D",
      "Age": "6 year- 7 year",
    },

    {
      "Name": "Amateur",
      "Age": "6 year- 7 year",
    },
    
   
  ]);


  return (
    <>
    <div className='container-fluid'>
      <div className='row main-containers m-5 gap-5'>
    <div className="col-lg-6 usermanagement_chart_wrap">
    <div className="row">
      <div className="col-md-12">
        <div className="usermanagement_chart_header">
        <div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>           
            <h4 className="headings">Soccer Categories</h4>
            
            <button className="btn btn-colors"  >+ Add New</button>
          </div>
        </div>
        <div className="table_body_wrap new_wrap">
          <table className="table table-borderless">
            <thead className='table-strip table-success'>
              <tr >
                <th>User Name</th>
                <th>Age</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user, index) => (
                <tr key={user.id}>
                  
                  <td>{user.Name}</td>
                  <td>{user.Age}</td>
                  
                 
                  <td>
                  <a className="btn row_end_btns">
                    <img src={editLogo} alt="" />
                    </a>
                    <a
                      className="btn row_end_btns"
                      // onClick={() => handleViewDetails(user)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-slash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                      </svg>
                    </a>
                    <a className="btn row_end_btns">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="red"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  </div>
  <div className=" col-lg-5 usermanagement_chart_wrap">
    <div className="row">
      <div className="col-md-12">
        <div className="usermanagement_chart_header">
        <div className="d-flex justify-content-between align-items-center" style={{width:'100%'}}>          
           <h4 className="headings">Soccer Series</h4>
           
           <button className="btn btn-colors"  >+ Add New</button>
          
          </div>
        </div>
        <div className="table_body_wrap new_wrap">
          <table className="table table-borderless">
            <thead className='table-strip table-success'>
              <tr >
                <th>User Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {seriseData.map((user, index) => (
                <tr key={user.id}>    
                  <td>{user.Name}</td>
                  <td>
                  <a className="btn row_end_btns">
                    <img src={editLogo} alt="" />
                    </a>
                    <a
                      className="btn row_end_btns"
                      // onClick={() => handleViewDetails(user)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        className="bi bi-eye-slash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7 7 0 0 0-2.79.588l.77.771A6 6 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13 13 0 0 1 14.828 8q-.086.13-.195.288c-.335.48-.83 1.12-1.465 1.755q-.247.248-.517.486z" />
                        <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829" />
                        <path d="M3.35 5.47q-.27.24-.518.487A13 13 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7 7 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12z" />
                      </svg>
                    </a>
                    <a className="btn row_end_btns">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="red"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" />
                      </svg>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      </div>
    </div>
  </div>
  </div>
  </div>
  </>
  )
}

export default Soccer