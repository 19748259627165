import React from 'react';
import ApexCharts from 'react-apexcharts';
import ThreeDots from "../../../../../../assets/three-dots.svg";
import './PaymentChart1.css';

const PaymentCharts1 = () => {
  const paymentoptions = {
    series: [{
      name: "Payments",
      data: [1750, 2250, 2500, 2000, 2750, 2350, 3000]
    }],
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
        }
      }]
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [" ", "6 May", "11 May", "20 May", "27 May", "31 May", " "],
    }
  };

  const refundoptions = {
    series: [{
      name: "Refunds",
      data: [40, 20, 25, 10, 0, 15]
    }],
    chart: {
      type: 'line',
      zoom: {
        enabled: false
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: '100%'
          },
        }
      }]
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5
      },
    },
    xaxis: {
      categories: [" ", "6 May", "11 May", "20 May", "27 May", "31 May", " "],
    }
  };

  return (
    <div className='main-component'>
      <div className='row gap'>
        <div className='card card-size'>
          <div className="card-body-head-wrap">
            <h6>Payments</h6>
            <div className="amount">
              <h5>$71,177.00</h5>
              <img src={ThreeDots} alt="Three Dots Icon" />
            </div>
          </div>
          <div className="chart-container">
            <ApexCharts options={paymentoptions} series={paymentoptions.series} type="line" height={350} />
          </div>
        </div>
        <div className='card card-size'>
          <div className="card-body-head-wrap">
            <h6>Refunds</h6>
            <div className="amount">
              <h5>$219.00</h5>
              <img src={ThreeDots} alt="Three Dots Icon" />
            </div>
          </div>
          <div className="chart-container">
            <ApexCharts options={refundoptions} series={refundoptions.series} type="line" height={350} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentCharts1;
