import React, { useEffect } from "react";
import './ViewUserDetails.css';
import { useDispatch, useSelector } from "react-redux";
import { getbyidusers } from '../../../Redux/Slice/UserSlice';
import { fetchAllReels, reelsgetbyid } from "../../../Redux/Slice/ReelsSlice";

const ViewUserDetails = ({ user, onClose }) => {
  const dispatch = useDispatch();
  const userDATA = useSelector((state) => state?.users?.users?.data?.[0]);
  const id = user?._id;
  const ReelsData = useSelector((state) => state?.Reel?.users?.[0]);
 console.log("object",ReelsData);


  useEffect(() => {
    if (user) {
      dispatch(getbyidusers(id));
      // dispatch(reelsgetbyid(id));
   
    }
  }, [dispatch, user]);

  useEffect(() => {
      dispatch(fetchAllReels());
  }, []);


  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="m-5 bg-light mainbody">
        <div className="row mainrow">
          <div className="col-3 card detailscol">
            <h3>{userDATA?.Name}</h3>
            <table>
              <tbody>
                <tr>
                  <td>User ID<span>:</span></td>
                  <td className="tdd">#{userDATA?.uniqueId}</td>
                </tr>
                <tr>
                  <td>Email ID<span>:</span></td>
                  <td className="tdd">{userDATA?.email}</td>
                </tr>
                <tr>
                  <td>Join Date <span>:</span></td>
                  <td className="tdd">{formatDate(userDATA?.created_at)}</td>
                </tr>
                <tr>
                  <td>User Type<span>:</span></td>
                  <td className="tdd">{userDATA?.role}</td>
                </tr>
                <tr>
                  <td>Subscription Type<span>:</span></td>
                  <td className="tdd">{userDATA?.subscriptionId?.subscriptionName}</td>
                </tr>
                <tr>
                  <td>Number of Reels<span>:</span></td>
                  <td className="tdd">50</td>
                </tr>
                <tr>
                  <td>Block This User<span>:</span></td>
                  <td className="tdd">
                    <div className="Switch_bton_wrap">
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="sliderr"></span>
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-8 for_reels">
            <div className="row gap-4px">
              {ReelsData && (
                <div className="col-2">
                  <div className="">
                    <div className="card-body">
                      <div className="card_img_wrap">
                        <div className="video-player">
                          <div className="mainImage" href="/reels">
                            <video controls src={ReelsData.video[0]} alt="" width={176} height={315} className="imagesize" />
                            <span className="buttonTop">
                              <div className="Switch_wrap">
                                <label className="switch">
                                  <input type="checkbox" />
                                  <span className="sliderrs"></span>
                                </label>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="match_info_subtitles">
                        <p className="Dispaly_5">{ReelsData.title}</p>
                        <p className="secon_row dot">
                          Today 
                          <i className="fa-solid fa-period dotitem"></i>
                          {ReelsData.viewsonreel} Views
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewUserDetails;
