import React, { useState } from 'react';
import "./Finance.css";

import Overview from '../FinancePage/Components/Overview/Overview';
import NavigationBar from '../../../components/Header/Header';
import Payments from '../FinancePage/Components/payments/Payments';
import Customer from '../FinancePage/Components/Customers/Customers';
import Subscription from '../FinancePage/Components/Subscription/Subscription';
import { Link } from 'react-router-dom';

const FinancePage = () => {
    const [selectedSection, setSelectedSection] = useState("overview");

    return (
        <>
            <NavigationBar />
            <div className="dashboard-body container">
                <div className="position-relative">
                    <div className="earning_container">
                        <ul className='nav  flex-column flex-sm-row align-items-center gap-34px'>
                            <li className="nav-item">
                                <Link
                                    to="/finance/overview"
                                    className={`nav-link ${selectedSection === "overview" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("overview")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Overview
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/finance/payments"
                                    className={`nav-link ${selectedSection === "Payments" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("Payments")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Payments
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/finance/subscription"
                                    className={`nav-link ${selectedSection === "Subscription" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("Subscription")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Subscription
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="/finance/customers"
                                    className={`nav-link ${selectedSection === "Customers" ? "active" : ""}`}
                                    onClick={() => setSelectedSection("Customers")}
                                    style={{ cursor: "pointer" }}
                                >
                                    Customers
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="overview" className="wrapper">
                    <div className="job-list-one style-two position-relative mb-20">
                        {selectedSection === "overview" && <Overview />}
                        {selectedSection === "Payments" && <Payments />}
                        {selectedSection === "Subscription" && <Subscription />}
                        {selectedSection === "Customers" && <Customer />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default FinancePage;
