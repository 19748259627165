import React, { useState } from 'react';
// import "./ProgressBar.css";

const PaymentProgressBarChart = () => {
    const [progressValues, setProgressValues] = useState({
        TerryBator: 2171.14,
        Marleygeorge: 1702.14,
        LiviaGedit: 1512.36,
        HannaBotosh:2171.14,
        GretchenHerwitz: 1702.14,
        paitynSairs: 1512.36,
    });

    const maxProgress = Math.max(progressValues.TerryBator, progressValues.Marleygeorge, progressValues.LiviaGedit,progressValues.HannaBotosh,progressValues.GretchenHerwitz,progressValues.paitynSairs);

    const Progress = ({ value, color }) => {
        const done = (value / maxProgress) * 50;

        // Construct backgroundColor from color object
        const backgroundColor = `rgb(${color.red}, ${color.green}, ${color.blue})`;

        const style = {
            opacity: 1,
            width: `${done}%`,
            backgroundColor: backgroundColor,
            borderRadius: '50px'
        };

        return (
            <div className="progress">
                <div className="progress-done" style={style}>
                </div>
            </div>
        );
    };

    return (
        <div className='main_progressbar'>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label" >
                    <label htmlFor="monthly">Terry Bator:</label>
                    <span className="progress-bar-value" >
                        ${progressValues.TerryBator}
                    </span>
                </div>
                <Progress value={progressValues.TerryBator} color={{ red: 30, green: 126, blue: 15}} />
            </div>

            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="daily">Marley George:</label>
                    <span className="progress-bar-value">
                        ${progressValues.Marleygeorge}
                    </span>
                </div>
                <Progress value={progressValues.Marleygeorge} color={{ red: 30, green: 126, blue: 15}} />
            </div>

            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Livia Gedit:</label>
                    <span className="progress-bar-value">
                        ${progressValues.LiviaGedit}
                    </span>
                </div>
                <Progress value={progressValues.LiviaGedit} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Hanna Botosh:</label>
                    <span className="progress-bar-value">
                        ${progressValues.HannaBotosh}
                    </span>
                </div>
                <Progress value={progressValues.HannaBotosh} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">Gretchen Herwitz:</label>
                    <span className="progress-bar-value">
                        ${progressValues.GretchenHerwitz}
                    </span>
                </div>
                <Progress value={progressValues.GretchenHerwitz} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
            <div className="progress-bar-itemss">
                <div className="progress-bar-label">
                    <label htmlFor="annually">paityn Sairs:</label>
                    <span className="progress-bar-value">
                        ${progressValues.paitynSairs}
                    </span>
                </div>
                <Progress value={progressValues.paitynSairs} color={{ red: 30, green: 126, blue: 15 }} />
            </div>
        </div>
    );
};

export default PaymentProgressBarChart;
