import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllSubscription,CreateSubscription } from "../Api/SubscriptionApi"




const initialState = {
    sunscriptions: [],
    page: 1,
    loading: false,
    error: null,
};

export const createSubscription = createAsyncThunk  (
    'sunscriptions/createSubscription',
    async (data) => {
        const { values, avatarFil } = data
        try {
            const response = await CreateSubscription(values);
            const imageid = response.data.data._id
            // if (response.data.isSuccess) {
            //     createProfile({ imageid, avatarFil })
            // }
            return response.data;
        } catch (error) {
            console.error("Error creating user:", error);
            throw error;
        }
    }
);



export const fetchAllSubscription = createAsyncThunk(
    'sunscriptions/fetchAllSubscription',
    async (data) => {
        try {
          const response = await getAllSubscription(data);
          console.log(response,"rrrrrrrrrrr")
            return response.data;
        } catch (error) {
            console.error("Error fetching users:", error);
            throw error;
        }
    }
);









const subscriptionSlice = createSlice({
    name: 'SuscriptionSlice',
    initialState,
    reducers: {
      setPage:(state,action)=>{
        state.page=action.payload
      }
    },
    extraReducers: (builder) => {
        builder
          .addCase(createSubscription.fulfilled, (state, action) => {
            state.loading = false;
          })
          .addCase(createSubscription.pending, (state, action) => {
            state.loading = true;
          })
          .addCase(createSubscription.rejected, (state, action) => {
            state.loading = false;
            state.error = "error";
          })
            // get all users----------

            .addCase(fetchAllSubscription.pending, (state) => {
              state.status = 'loading';
            })
            .addCase(fetchAllSubscription.fulfilled, (state, action) => {
              state.status = 'succeeded';
              state.users = action.payload;
            })
            .addCase(fetchAllSubscription.rejected, (state, action) => {
              state.status = 'failed';
              state.error = action.error.message;
            });
         

            // delete----------

          // .addCase(fetchdeleteUser.fulfilled, (state, action) => {
          //   state.loading = false;
          // })

          // search

          // .addCase(fetchsearchUser.fulfilled,(state,action)=>{
          //   state.users = action.payload;
          //   state.loading = false;
          // })
          
          
          
      },
    });
    export const { setPage } = subscriptionSlice.actions
    export default subscriptionSlice.reducer;
    