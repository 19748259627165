import React from 'react';
import SubChart3 from '../charts/SubChart3';
import BarChart2 from '../charts/BarChart2';
import ThreeDots from '../../../../../../assets/three-dots.svg';
import CalenderIcon from '../../../../../../assets/calender-icon.svg';
import ApexCharts from 'react-apexcharts';
import './Section1.css';

const Section1 = () => {
  const paymentoptions = {
    series: [{
      name: "Payments",
      data: [30, 45, 35, 45, 50, 55, 45]
    }],
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false
      }
    },
    stroke: {
      curve: 'smooth',
      color: "#0E9767",
      opacity: 0.5
    },
    title: {
      align: 'left'
    },
    grid: {
      row: {
        colors: ['transparent'],
        opacity: 0.5
      }
    },
    xaxis: {
      categories: [" ", "6 May", "11 May", "20 May", "27 May", "31 May", " "],
    }
  };

  return (
    <>
      <div className="main_wrap_for_overviews main_wrap_for_Subscriptions">
        <div className="row justify-content-center mt-50px">
          <div className='col-md-6 col-lg-12 card cardsize-4'>
            <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
              <h4>Subscriptions</h4>
              <div className="d-flex align-items-center gap-30px">
                <span className='h5 m-0'>1344</span>
                <img src={ThreeDots} alt="Three Dots Icon" />
              </div>
            </div>
            <div className="progress-chart-item">
              <ApexCharts options={paymentoptions} series={paymentoptions.series} type="line" height={230} width="100%" />
            </div>
          </div>
          <div className="col-md-6 col-lg-12 card cardsize-4">
            <div className="subscription_card_body_wrap">
              <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
                <h6>Top Subscription Prices</h6>
                <div className="d-flex align-items-center gap-30px">
                  <img src={CalenderIcon} alt="Calendar Icon" />
                  <img src={ThreeDots} alt="Three Dots Icon" />
                </div>
              </div>
              <div className="progress-bar-item">
                <BarChart2 />
              </div>
            </div>
          </div>
          <div className="col-12 card cardsize-4">
            <div className="card_body_head_wrap d-flex align-items-center justify-content-between">
              <h6>Subscriptions by Status</h6>
              <div className="d-flex align-items-center gap-30px">
                <img src={CalenderIcon} alt="Calendar Icon" />
                <img src={ThreeDots} alt="Three Dots Icon" />
              </div>
            </div>
            <SubChart3 />
            
          </div>
        </div>
      </div>
    </>
  );
}

export default Section1;
