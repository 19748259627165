import React from 'react'
import { Card, CardContent, Typography } from "@mui/material";
import Dollor from "../../../../../../assets/logo/dollor icon.png";
import logo2 from "../../../../../../assets/logo/cllendedollor.png";
import logo3 from "../../../../../../assets/logo/avrageorg.png";
import logo4 from "../../../../../../assets/logo/greengroup.png";
import logo5 from "../../../../../../assets/logo/bluepersent.png";
import './Section2.css'
const Section2 = () => { 
  return (
    <>  
    <div className=" main_wrap_for_overvieww">
    <div className="row justify-content-center gap-3px mt-5">
      <Card className="card  cardsize">
        <CardContent className="card-content ">
          <div className="d-inline-flex gap-24px ">
            <img src={Dollor} alt="dollor" width="40px" height="45px" />
            <div>
              <Typography className="payment-title" component="div">
                Subscription
              </Typography>
              <Typography className="payment-amount " component="div">
                <span className="h4"> 1,254</span>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>

      <Card className="card  cardsize">
        <CardContent className="card-content">
          <div className="d-inline-flex gap-24px">
            <img src={logo2} alt="dollor" width="40px" height="45px" />
            <div>
              <Typography
                className="payment-title titledir"
                component="div"
              >
                MRR
              </Typography>
              <Typography className="payment-amount" component="div">
                <span className="h4">$57,734.28K </span>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="card  cardsize">
        <CardContent className="card-content">
          <div className="d-inline-flex gap-24px">
            <img src={logo3} alt="dollor" width="40px" height="45px" />
            <div>
              <Typography
                className="payment-title titledir"
                component="div"
              >
                Ave. MRR
              </Typography>
              <Typography className="payment-amount" component="div">
                <span className="h4"> $41.09 </span>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="card cardsize">
        <CardContent className="card-content">
          <div className="d-inline-flex gap-24px">
            <img src={logo4} alt="dollor" width="40px" height="45px" />
            <div>
              <Typography
                className="payment-title titledir"
                component="div"
              >
                Discount
              </Typography>
              <Typography className="payment-amount" component="div">
                <span className="h4"> $3,824.92</span>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card className="card cardsize">
        <CardContent className="card-content">
          <div className="d-inline-flex gap-24px">
            <img
              className=""
              src={logo5}
              alt="dollor"
              width="40px"
              height="45px"
            />
            <div>
              <Typography
                className="payment-title titledir"
                component="div"
              >
                Ave. Discount
              </Typography>
              <Typography className="payment-amount" component="div">
                <span className="h4"> $2.75</span>
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  </div>
{/* second Section------------- */}
<div className=" main_wrap_for_overvieww">
<div className="row justify-content-center gap-3px mt-5">
  <Card className="card  cardsize">
    <CardContent className="card-content ">
      <div className="d-inline-flex gap-24px ">
        <img src={Dollor} alt="dollor" width="40px" height="45px" />
        <div>
          <Typography className="payment-title" component="div">
            Subscription
          </Typography>
          <Typography className="payment-amount " component="div">
            <span className="h4"> 1,254</span>
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>

  <Card className="card  cardsize">
    <CardContent className="card-content">
      <div className="d-inline-flex gap-24px">
        <img src={logo2} alt="dollor" width="40px" height="45px" />
        <div>
          <Typography
            className="payment-title titledir"
            component="div"
          >
            MRR
          </Typography>
          <Typography className="payment-amount" component="div">
            <span className="h4">$57,734.28K </span>
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>
  <Card className="card  cardsize">
    <CardContent className="card-content">
      <div className="d-inline-flex gap-24px">
        <img src={logo3} alt="dollor" width="40px" height="45px" />
        <div>
          <Typography
            className="payment-title titledir"
            component="div"
          >
            Ave. MRR
          </Typography>
          <Typography className="payment-amount" component="div">
            <span className="h4"> $41.09 </span>
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>
  <Card className="card cardsize">
    <CardContent className="card-content">
      <div className="d-inline-flex gap-24px">
        <img src={logo4} alt="dollor" width="40px" height="45px" />
        <div>
          <Typography
            className="payment-title titledir"
            component="div"
          >
            Discount
          </Typography>
          <Typography className="payment-amount" component="div">
            <span className="h4"> $3,824.92</span>
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>
  <Card className="card cardsize">
    <CardContent className="card-content">
      <div className="d-inline-flex gap-24px">
        <img
          className=""
          src={logo5}
          alt="dollor"
          width="40px"
          height="45px"
        />
        <div>
          <Typography
            className="payment-title titledir"
            component="div"
          >
            Ave. Discount
          </Typography>
          <Typography className="payment-amount" component="div">
            <span className="h4"> $2.75</span>
          </Typography>
        </div>
      </div>
    </CardContent>
  </Card>
</div>
</div>
</>

  )
}

export default Section2